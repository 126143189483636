import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Breadcrumbs, Button, Container, Grid, Link, Typography, Popover, } from '@material-ui/core';
//import { customerApi } from '../../__fakeApi__/customerApi';
//import { CustomerListTable } from '../../components/dashboard/customer';
import useMounted from '../../../hooks/useMounted';
import AddPermission from './AddPermission.js';
import ChevronRightIcon from '../../../icons/ChevronRight';
import DownloadIcon from '../../../icons/Download';
import PlusIcon from '../../../icons/Plus';
import UploadIcon from '../../../icons/Upload';
import useSettings from '../../../hooks/useSettings';
import gtm from '../../../lib/gtm';
import HttpService from '../../../services/HttpService.js';
import UrlService from '../../../services/UrlService.js';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';


import CheckboxRenderer from "../../../aggrid/CheckboxEditor.js";
import DeleteRenderer from "../../../aggrid/DeleteRenderer.js";
import LocaleEn from "../../../aggrid/locale.en.js";
import LocaleCs from "../../../aggrid/locale.cs.js";
import LocaleRu from "../../../aggrid/locale.ru.js";
import { useTranslation } from 'react-i18next';

import MultiSelectSimple from "../../../aggrid/MultiSelect.js";
import {LicenseManager} from "ag-grid-enterprise";
LicenseManager.setLicenseKey("CompanyName=GTS - Global Tungsten Solution s.r.o.,LicensedGroup=GTSDB,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-015194,ExpiryDate=23_May_2022_[v2]_MTY1MzI2MDQwMDAwMA==d202e751d68f01012278c38da399e166");



const Permissions = () => {
    const { t } = useTranslation();





    console.log(t('cs'));

    const customComparator = (valueA, valueB) => {
        if (valueA!=null && valueB!=null)
            return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    };

    var lt;
    if (t('cs')=='cs') lt=LocaleCs;
    if (t('cs')=='en') lt=LocaleEn;
    if (t('cs')=='ru') lt=LocaleRu;

    var coldefs=[
        {
            headerName: t('Celé jméno'),
            field: 'full_name',
            editable: true,
            checkboxSelection:true,
            filter: 'agTextColumnFilter',
            sort: 'asc',
            comparator: customComparator
        },

        {
            headerName: t('Právo'),
            field: 'name',
            editable: true,

            filter: 'agTextColumnFilter',

            comparator: customComparator
        },
        {headerName:t('Odstranit'),
            cellRenderer:"DeleteRenderer",
            minWidth: 90,
            cellRendererParams: {
                gridType: "permissions"
            },
            suppressMenu: true,}
    ];

    const mounted = useMounted();
    const { settings } = useSettings();
    const [rowdata, setRowdata] = useState([]);
    const [open, setOpen] = useState(false);
    const [columndefs, setColumndefs] = useState(coldefs);

    const [gridApi, setgridApi] = useState({});
    const [loctext, setloctext] = useState(lt);
    const [gridColumnApi, setgridColumnApi] = useState({});








    const defaultColDef={
        flex: 1,
        minWidth: 170,
        editable: false,
        resizable: true,
        suppressMovableColumns:true,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        suppressContextMenu:true,
    };

    const frameworkComponents={
        CheckboxRenderer: CheckboxRenderer,
        MultiSelectSimple: MultiSelectSimple,
        DeleteRenderer: DeleteRenderer,

    };




    const getRowdata = useCallback(async () => {
        HttpService.get(UrlService.apiDomain()+'api/permissions')
            .then(res => {

                setRowdata(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [mounted]);




    useEffect(() => {
        getRowdata();
    }, [getRowdata]);



    const onCellCValueChanged = (event) => {


        HttpService.put(UrlService.apiDomain()+'api/permissions/'+event.data.id, event.data)
            .then(res => {
                console.log('Successfully updated');


                event.node.setData(res.data.entry);
            })
            .catch((error) => {
                console.log(error);
            })


    }

    const onGridReady = (params) => {
        setgridApi(params.api);
        setgridColumnApi(params.columnApi);

        params.api.setDomLayout('autoHeight');
        document.querySelector('#myGrid').style.height = '';

        var columnState = JSON.parse(localStorage.getItem('permissions_column_state'));
        if (columnState) {
            params.columnApi.setColumnState(columnState);
        }
    };

    const onDragStopped = (params) => {

        var columnState = JSON.stringify(params.columnApi.getColumnState());
        localStorage.setItem('permissions_column_state', columnState);
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleAdd = (data) => {
        HttpService.post(UrlService.apiDomain()+'api/permissions', data)
            .then(res => {

                if (res.data.result=='failed') {
                    alert(res.data.message);
                } else {
                    console.log('Successfully updated');
                    handleClose();
                    let r = gridApi.updateRowData({
                        add: [res.data.entry]
                    });

                    gridApi.flashCells({rowNodes: [r.add[0]]});




                }

            })
            .catch((error) => {
                alert(error);
            });

    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setloctext(lt);
        setColumndefs(coldefs);
    }, [t('cs')]);


    return (
        <>
        <Helmet>
            <title>Dashboard: Permissions List | AWA Partners</title>
        </Helmet>
        <Popover
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'top'
            }}
            keepMounted
            onClose={handleClose}
            open={open}
            PaperProps={{
                sx: { width: 540 }
            }}
        >
            <AddPermission onAdd={handleAdd} />
        </Popover>
        <Box
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                py: 8
            }}
        >
            <Container maxWidth={settings.compact ? 'xl' : false}>
                <Grid
                    container
                    justifyContent="space-between"
                    spacing={3}
                >
                    <Grid item>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                        >
                            {t('Práva')}
                        </Typography>


                    </Grid>
                    <Grid item>
                        <Box sx={{ m: -1 }}>
                            <Button
                                color="primary"
                                startIcon={<PlusIcon fontSize="small" />}
                                sx={{ m: 1 }}
                                variant="contained"
                                onClick={()=>{

                                    setOpen(true);
                                }}
                            >
                                {t('Přidat')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ mt: 3 }}>
                    <div style={{ 'height': '100%', width:'100%' }} id="myGrid" className="ag-theme-material">
                        <AgGridReact
                            modules={AllCommunityModules}
                            rowData={rowdata}
                            columnDefs={columndefs}
                            defaultColDef={defaultColDef}
                            onGridReady={onGridReady}
                            onDragStopped={onDragStopped}
                            onCellValueChanged={onCellCValueChanged}
                            frameworkComponents={frameworkComponents}
                            enableCellChangeFlash={true}
                            rowSelection={'multiple'}
                            suppressContextMenu={true}
                            localeText={loctext}
                            popupParent={document.querySelector('body')}
                        />
                    </div>
                </Box>
            </Container>
        </Box>
        </>
    );
};

export default Permissions;
