import {lazy, Suspense} from "react";
import LoadingScreen from "../../components/LoadingScreen";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import BusinessIcon from "@material-ui/icons/Business";
import ApartmentIcon from "@material-ui/icons/Apartment";
import StoreIcon from "@material-ui/icons/Store";
import KeyIcon from "@material-ui/icons/VpnKey";
import AssignmentIcon from '@material-ui/icons/Assignment';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import SpeedIcon from '@material-ui/icons/Speed';
import BallotIcon from '@material-ui/icons/Ballot';
import {AccountBalance, Category, Paid, Euro, AccountTree} from "@material-ui/icons";
import getColumnDefinitions from "../../services/getColumnDefinitions";

const Loadable = (Component) => (props) => (
    <Suspense fallback={<LoadingScreen/>}>
        <Component {...props} />
    </Suspense>
);

const Companies = Loadable(lazy(() => import('../companies')));
const Buildings = Loadable(lazy(() => import('../buildings')));
const Facilities = Loadable(lazy(() => import('../facilities')));
const Clients = Loadable(lazy(() => import('../clients')));
const Customers = Loadable(lazy(() => import('../clients/customers')));
const Rent = Loadable(lazy(() => import('../rent')));
const IssuedInvoices = Loadable(lazy(() => import('../issued_invoices')));
const MeasurementUnits = Loadable(lazy(() => import('../measurement_units')));
const MeterReadings = Loadable(lazy(() => import('../meter_readings')));
const MeterTypes = Loadable(lazy(() => import('../meter_types')));
const ReceivedInvoices = Loadable(lazy(() => import('../received_invoices')));
const PendingInvoices = Loadable(lazy(() => import('../received_invoices/pending_invoices')));
const Suppliers = Loadable(lazy(() => import('../suppliers')));
const SupplierAccountNumbers = Loadable(lazy(() => import('../supplier_account_numbers')));
const AccountNumbers = Loadable(lazy(() => import('../account_number')));
const WireTransfers = Loadable(lazy(() => import('../wire_transfer/index')));
const InvoiceItems = Loadable(lazy(() => import('../invoice_items/index')));
const InvoiceItemNames = Loadable(lazy(() => import('../invoice_items/invoice_item_names/index')));
const Payments = Loadable(lazy(() => import('../payments/index')));
const Currencies = Loadable(lazy(() => import('../currency/index')));
const RegularPayments = Loadable(lazy(() => import('../regular_payments/index')));
const Debts = Loadable(lazy(() => import('../debts/index')));
const AdditionalTexts = Loadable(lazy(() => import('../additional_texts/index')));

const CompanyReport = Loadable(lazy(() => import('../companies/companiesReport')));
const IssuedInvoiceReport = Loadable(lazy(() => import('../issued_invoices/issuedInvoicesReport')));
const RentReport = Loadable(lazy(() => import('../rent/rentReport')));

const Projects = Loadable(lazy(() => import('../projects/index')));

const permissions = JSON.parse(localStorage.getItem('my_permissions'));

const sidebarMain = [
    {
        'title': 'Naše firmy',
        'icon': <BusinessIcon fontSize="small"/>,
        'element': <Companies/>,
        'path': 'companies',
    },
    {
        'title': 'Report společností',
        'icon': <BusinessIcon fontSize="small"/>,
        'element': <CompanyReport/>,
        'path': 'companies_report',
        'permission': 'settings.company_report',
    },
    {
        'title': 'Budovy',
        'icon': <ApartmentIcon fontSize="small"/>,
        'element': <Buildings/>,
        'path': 'buildings',
    },
    {
        'title': 'Jednotky',
        'icon': <StoreIcon fontSize="small"/>,
        'element': <Facilities/>,
        'path': 'facilities',
    },
    {
        'title': 'Nájemci',
        'icon': <AccessibilityIcon fontSize="small"/>,
        'element': <Clients/>,
        'path': 'clients',
    },
    {
        'title': 'Odběratele',
        'icon': <AccessibilityIcon fontSize="small"/>,
        'element': <Customers/>,
        'path': 'customers',
        'model': 'clients',
        'permission': 'customers.view',
    },
    {
        'title': 'Nájmy',
        'icon': <KeyIcon fontSize="small"/>,
        'element': <Rent/>,
        'path': 'rents',
    },
    {
        'title': 'Report nájmů',
        'icon': <KeyIcon fontSize="small"/>,
        'element': <RentReport/>,
        'path': 'rent_report',
        'permission': 'settings.rent_report',
    },
    {
        'title': 'Vystavené faktury',
        'icon': <AssignmentIcon fontSize="small"/>,
        'element': <IssuedInvoices/>,
        'path': 'issued_invoices',
    },
    {
        'title': 'Report vystavených faktur',
        'icon': <AssignmentIcon fontSize="small"/>,
        'element': <IssuedInvoiceReport/>,
        'path': 'issued_invoices_report',
        'permission': 'settings.invoice_report',
    },
    {
        'title': 'Přijaté faktury',
        'icon': <AssignmentIcon fontSize="small"/>,
        'element': <ReceivedInvoices/>,
        'path': 'received_invoices',
    },
    {
        'title': 'Platby faktur',
        'icon': <AssignmentIcon fontSize="small"/>,
        'element': <PendingInvoices/>,
        'path': 'pending_invoices',
        'permission': 'settings.pending_invoices',
    },
    {
        'title': 'Měrné jednotky',
        'icon': <SquareFootIcon fontSize="small"/>,
        'element': <MeasurementUnits/>,
        'path': 'measurement_units',
    },
    // {
    //     'title': 'Stavy měříčů',
    //     'icon': <SpeedIcon fontSize="small"/>,
    //     'element': <MeterReadings/>,
    //     'path': 'meter_readings',
    // },
    // {
    //     'title': 'Typy měříčů',
    //     'icon': <BallotIcon fontSize="small"/>,
    //     'element': <MeterTypes/>,
    //     'path': 'meter_types',
    // },
    {
        'title': 'Dodavatele',
        'icon': <AccessibilityIcon fontSize="small"/>,
        'element': <Suppliers/>,
        'path': 'suppliers',
    },
	{
        'title': 'Čísla dodavatelských účtů',
        'icon': <AccessibilityIcon fontSize="small"/>,
        'element': <SupplierAccountNumbers/>,
        'path': 'supplier_account_numbers',
    },
    {
        'title': 'Naše účty',
        'icon': <AccountBalance fontSize="small"/>,
        'element': <AccountNumbers/>,
        'path': 'account_numbers',
    },
    {
        'title': 'Bankovní převody',
        'icon': <Paid fontSize="small"/>,
        'element': <WireTransfers/>,
        'path': 'wire_transfers',
    }, {
        'title': 'Příchozí platby',
        'icon': <Paid fontSize="small"/>,
        'element': <Payments/>,
        'path': 'payments',
    },
    {
        'title': 'Položky V. faktur',
        'icon': <Category fontSize="small"/>,
        'element': <InvoiceItemNames/>,
        'path': 'invoice_item_names',
    },
    {
        'title': 'Dodatečné texty',
        'icon': <Category fontSize="small"/>,
        'element': <AdditionalTexts/>,
        'path': 'additional_texts',
    },
    {
        'title': 'Měny',
        'icon': <Euro fontSize="small"/>,
        'element': <Currencies/>,
        'path': 'currencies',
    },
    {
        'title': 'Pravidelné platby',
        'icon': <Euro fontSize="small"/>,
        'element': <RegularPayments/>,
        'path': 'regular_payments',
    },
    {
        'title': 'Dluhy',
        'icon': <Euro fontSize="small"/>,
        'element': <Debts/>,
        'path': 'debts',
    },
    {
        'title': 'Projekty',
        'icon': <AccountTree fontSize="small"/>,
        'element': <Projects/>,
        'path': 'projects',
    }
].filter(item => {
    if(item.permission){
        return permissions?.includes(item.permission);
    }
    return permissions?.includes((item.model || item.path) + ".view");
});

export default sidebarMain;
