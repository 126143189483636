import React, {useState} from "react";
import {Checkbox} from "@material-ui/core";

export default function CheckboxEditor(props) {
    const [checked, setChecked] = useState(props.value !== 'false' && props.value);

    const handleChange = (val) => {
        props.node.setDataValue(props.column.colId, val);
    }

    return (
        <Checkbox sx={props.style ?? undefined} checked={checked} onChange={(event) => {
            if(props.disabled) return;
            let newValue = event.target.checked;
            if (window.confirm(`Are you sure you want to ${props.colDef.headerName} change to ${newValue}`)) {
                setChecked(newValue)
                handleChange(newValue ? 1 : 0);
            }
        }}/>
    )
}