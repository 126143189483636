import {useEffect} from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PropTypes from 'prop-types';
import {Avatar, Box, Button, Divider, Drawer, Link, Typography} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useAuth from '../../hooks/useAuth';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import UsersIcon from '../../icons/Users';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import {useTranslation} from 'react-i18next';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import CreditScoreIcon from '@material-ui/icons/CreditScore';
import DescriptionIcon from '@material-ui/icons/Description';
import HomeIcon from '@material-ui/icons/Home';
import TranslateIcon from '@material-ui/icons/Translate';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import SettingsIcon from '@material-ui/icons/Settings';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Summarize from '@material-ui/icons/Summarize';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import PasswordIcon from '@material-ui/icons/Password';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import sidebarColumns from "../../pages/routes/sidebarColumns";
import sidebarMain from "../../pages/routes/sidebarMain";
import {Article} from "@material-ui/icons";

const DashboardSidebar = (props) => {
    const {onMobileClose, openMobile} = props;
    const location = useLocation();
    const {user} = useAuth();
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const {t} = useTranslation();
    var permissions = JSON.parse(localStorage.getItem('my_permissions'));
    var roles = [];
    JSON.parse(localStorage.getItem('my_user')).roles.forEach((r) => {
        roles.push(r.name);
    });
    const sections = [{
        title: t('Úvod'),
        items: [
            {
                title: t('Domovská stránka'),
                path: '/dashboard',
                icon: <HomeIcon fontSize="small"/>,
            }
        ]
    }];
    const settings_children = [];
    const tables = [];

    for (const element of sidebarMain) {
        if (!element.permission || permissions.includes(element.permission)) {
            tables.push({
                title: t(element.title),
                path: `/dashboard/${element.path}`,
                icon: element.icon,
            });
        }
    }

    sections.push(
        {
            title: t('Moduly'),
            items: tables
        }
    );

    var permissions = JSON.parse(localStorage.getItem('my_permissions'));
    var roles = [];
    JSON.parse(localStorage.getItem('my_user')).roles.forEach((r) => {
        roles.push(r.name);
    });


    const column_settings_children = [];
    for (const columnsRoute of sidebarColumns) {
        if (!sidebarColumns.permission || permissions.includes(sidebarColumns.permission)) {
            column_settings_children.push({
                title: t(columnsRoute.title),
                path: `/dashboard/${columnsRoute.model}_columns`,
            });
        }
    }

    // Settings
    if (permissions.includes('settings.access')) settings_children.push({
        title: t('Přístup'),
        icon: <UsersIcon fontSize="small"/>,
        children: [
            {
                title: t('Uživatele'),
                path: '/dashboard/users'
            },
            {
                title: t('Role'),
                path: '/dashboard/roles'
            },
            {
                title: t('Práva'),
                path: '/dashboard/permissions'
            }
        ]
    });

    // if (permissions.includes('settings.translations')) settings_children.push({
    //     title: t('Překlady'),
    //     path: '/dashboard/translations',
    //     icon: <TranslateIcon fontSize="small"/>,
    //     children: [
    //         {
    //             title: t('Ruština'),
    //             path: '/dashboard/translations/ru'
    //         },
    //
    //     ]
    // });

    if (permissions.includes('settings.columns')) settings_children.push(
        {
            title: t('Kolonky'),
            icon: <ViewWeekIcon fontSize="small"/>,
            children: column_settings_children
        });

    if (permissions.includes('settings.mail_templates')) settings_children.push(
        {
            title: t('Šablóny emailů'),
            path: '/dashboard/mail_templates',
            icon: <DescriptionIcon fontSize="small"/>,
        });

    if (permissions.includes('settings.ondemand_log')) settings_children.push(
        {
            title: t('Log zobrazení'),
            path: '/dashboard/ondemand_log',
            icon: <PasswordIcon fontSize="small"/>,
        });

    if (permissions.includes('settings.view')) settings_children.push(
        {
            title: t('Ostatní nastavení'),
            path: '/dashboard/other_settings',

            icon: <SettingsIcon fontSize="small"/>,
        });

    if (permissions.includes('settings.view')) settings_children.push(
        {
            title: t('Šablóny dokumentů'),
            path: '/dashboard/document_templates',

            icon: <Article fontSize="small"/>,
        });

    if (permissions.includes('settings.view')) sections.push({
        title: t('Nastavení'),
        items: settings_children
    });


    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
    }, [location.pathname]);

    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
        >
            <Scrollbar options={{suppressScrollX: true}}>
                <Box
                    sx={{
                        display: {
                            lg: 'none',
                            xs: 'flex'
                        },
                        justifyContent: 'center',
                        p: 2
                    }}
                >
                    <RouterLink to="/">
                        <Logo
                            sx={{
                                height: 40,
                                width: 40
                            }}
                        />
                    </RouterLink>
                </Box>

                <Divider/>
                <Box sx={{p: 2}}>
                    {sections.map((section) => (
                        <NavSection
                            key={section.title}
                            pathname={location.pathname}
                            sx={{
                                '& + &': {
                                    mt: 3
                                }
                            }}
                            {...section}
                        />
                    ))}
                </Box>
                <Divider/>

            </Scrollbar>
        </Box>
    );

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: 'background.paper',
                        height: 'calc(100% - 64px) !important',
                        top: '64px !Important',
                        width: 280
                    }
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            onClose={onMobileClose}
            open={openMobile}
            PaperProps={{
                sx: {
                    backgroundColor: 'background.paper',
                    width: 280
                }
            }}
            variant="temporary"
        >
            {content}
        </Drawer>
    );
};

DashboardSidebar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

export default DashboardSidebar;
