const translat={
    'Přihlásit se': 'Sign in',
    'Přihlásit se do osobního profilu': 'Log in to your personal account',
    'Chybný formát emailu': 'Must be a valid email',
    'Email je povinný': 'Email is required',
    'Heslo je povinné': 'Password is required',
    'Heslo': 'Password',
    'Chybné přihašovací údaje': 'Invalid credentials',
    'Odhlásit se': 'Logout',
    'Nastavení': 'Settings',
    'Uživatele': 'Users',
    'Účty': 'Accounts',
    'Role': 'Roles',
    'Práva': 'Permissions',
    'Nástěnka': 'Dashboard',
    'Uživatel': 'User',
    'Jméno': 'Name',
    'Role': 'Role',
    'Email': 'Email',
    'Telefon': 'Phone',
    'Pozice': 'Position',
    'Aktivní': 'Active',
    'Přidat': 'Add new',
    'Uložit': 'Save',
    'cs': 'en',
    'Odstranit': 'Delete',
    'Potvrdit odstranění': 'Confirm to delete',
    'Jste si jisti, že chcete odstranit tuto položku a všechny položky s ní spojené?': 'Are you sure to delete this item and all other items associated with it?',
    'Právo': 'Permission',
    'Celé jméno': 'Full name',
    'Ne': 'No',
    'Ano': 'Yes',

}

export default translat;