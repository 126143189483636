import ModalWrapper from "../modal/ModalWrapper";
import {useEffect, useState} from "react";
import apiGet from "../../api/apiGet";
import GridWrapper from "./gridWrapper";
import DownloadAttachment from "../../aggrid/downloadAttachment";
import FileUpload from "../inputs/file/FileUpload";
import FileUploadWithSubmit from "../inputs/file/FileUploadWithSubmit";

export default function MultiFileModal({data, isOpen, onClose}) {
    const [attachments, setAttachments] = useState([]);

    useEffect(() => {
        let mounted = true;
        if (data) {
            apiGet(`${data.model}/${data.id}/attachments/${data.field}`).then(response => {
                if (mounted) {
                    if(response.data && response.data.length > 0) {
                        setAttachments(response.data);
                    } else {
                        console.log('No attachments found')
                    }
                }
            });
        }
        return function cleanup() {
            mounted = false
        };
    }, [data]);

    useEffect(() => {
        if (!isOpen) {
            setAttachments([]);
        }
    }, [isOpen]);

    return (
        <ModalWrapper
            size='xl'
            isOpen={isOpen}
            onClose={onClose}>
            {data && <GridWrapper
                style={{mt: 2}}
                helmet={`Dashboard: ${data.label} List | Belo estate`}
                title={data.label}
                data={attachments}
                frameworkComponents={{downloadAttachment: DownloadAttachment}}
                colDefs={[
                    {
                        headerName: "Soubor",
                        editable: false,
                        field: "attachment",
                    },
                    {
                        headerName: "Akce",
                        editable: false,
                        field: "id",
                        cellRenderer: "downloadAttachment",
                    },
                ]}>
                <FileUploadWithSubmit model={data.model} id={data.id} field={data.field}/>
            </GridWrapper>}
        </ModalWrapper>
    )
}