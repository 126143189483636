const translat={
'Editable': 'Upravovatelné',
'Visible': 'Viditelné',
'Employees': 'Uchazeči',
'Text': 'Text',
'Integer': 'Celé číslo',
'Decimals': 'Desetinné číslo',
'Yes/No': 'Ano/Ne',
'Select': 'Výběr',
'Multi Select': 'Multi výběr',
'Date': 'Datum',
'Date and time': 'Datum a čas',
'Languages': 'Jazyky',
'Countries': 'Země',
'Users': 'Uživatele',
'Custom': 'Vlastní',
'None': 'Žádný',
'hour': 'hodinou',
'hours': 'hodinami',
'minute': 'minutou',
'minutes': 'minutami',
'second': 'vteřinou',
'seconds': 'vteřinami',


}



export default translat;