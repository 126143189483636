import GridWrapper from "../../../../components/grid/gridWrapper";
import BasicAdd from "../../../../components/grid/basicAdd";

export default function DocumentTemplates() {
    return (
        <GridWrapper
            helmet="Dashboard: DocumentTemplates List | Belo estate"
            title="Šablóny dokumentů"
            model="document_templates">
            <BasicAdd model={"document_templates"}/>
        </GridWrapper>
    )

}