import {Autocomplete, LinearProgress, TextField} from "@material-ui/core";
import {memo, useEffect, useState} from "react";
import useFilter from "../../../hooks/useFilter";

export default memo(({
                         keySelector,
                         valueSelector,
                         onChange,
                         label,
                         data_array,
                         data_array_model,
                         name,
                         required,
                         disabled,
                         inputSx,
                     }) => {
    const filter = useFilter();
    const [options, setOptions] = useState(null);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        let filtered = filter.byAccessAndFilter(data_array, data_array_model);
        setOptions(filtered.map(item => {
            return {value: valueSelector(item), label: keySelector(item)}
        }));
    }, [data_array, data_array_model, filter, keySelector, valueSelector]);

    useEffect(() => {
        if(options?.length === 1){
            setSelected([options[0]]);
        }
    }, [options])

    useEffect(() => {
        let newValue = selected?.map(item => item.value).join('//');
        onChange(name, newValue, !required);
    }, [name, onChange, required, selected]);

    if (!data_array || !options) return <LinearProgress/>;

    return (
        <Autocomplete name={name} disabled={disabled} options={options}
                      onChange={(event, newValue) => {
                          setSelected(newValue);
                      }}
                      fullWidth
                      multiple
                      value={selected ?? []}
                      sx={inputSx}
                      renderInput={(params) =>
                          <TextField {...params} label={required ? label + '*' : label}
                                     inputProps={{ ...params.inputProps, maxLength: 30}}
                                     error={required && !selected}/>}/>
    )
}, (prev, next) => {
    return prev.disabled === next.disabled
        && prev.selected === next.selected
        && prev.data_array === next.data_array
});