import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Button, Chip, Divider, IconButton, Link, Toolbar } from '@material-ui/core';
import MenuIcon from '../icons/Menu';
import Logo from './Logo';
import { useTranslation } from 'react-i18next';
import LanguagePopover from './dashboard/LanguagePopover';
const MainNavbar = (props) => {
  const { onSidebarMobileOpen } = props;
    const { t } = useTranslation();
  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.secondary'
      }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              md: 'none'
            }
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/">
          <Logo
            sx={{
              display: {
                md: 'inline',
                xs: 'none'
              },
              height: 30,
              width: 118
            }}
          />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            alignItems: 'center',
            display: {
              md: 'flex',
              xs: 'none'
            }
          }}
        >
            {true == false &&
            <LanguagePopover />
            }

          <Divider
            orientation="vertical"
            sx={{
              height: 32,
              mx: 2
            }}
          />
            {localStorage.getItem('my_user')==null &&
                <>

          <Button
            color="primary"
            component="a"
            href="/dashboard"
            size="small"
            variant="contained"
          >
              {t('Přihlásit se')}
          </Button>
                </>
            }

            {localStorage.getItem('my_user')!=null &&
            <>

            <Button
                color="primary"
                component="a"
                href="/dashboard"
                size="small"
                variant="contained"
            >
                {t('Můj účet')}
            </Button>
            </>
            }

        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default MainNavbar;
