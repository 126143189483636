import {
    Dialog, Paper
} from "@material-ui/core";
import {useEffect, useState} from "react";

export default function ModalWrapper({isOpen, onClose, children, size}) {
    return (
        <Dialog
            fullWidth
            maxWidth={size ?? 'sm'}
            onClose={onClose}
            open={isOpen}
        >
            <Paper
                elevation={12}
                sx={{p: 2}}
            >
                {children}
            </Paper>
        </Dialog>
    )
}
