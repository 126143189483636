let apiDomain = "";
if (process.env.NODE_ENV === "production") {
  apiDomain = "https://api.beloestate.com/";
} else {
  // apiDomain = "http://localhost:8000/";
  apiDomain = "http://belo.loc/";
}

class UrlService {
  static apiDomain() {
    return apiDomain;
  }
  static loginUrl() {
    return apiDomain + "api/login";
  }
}

export default UrlService;
