import Button from '@material-ui/core/Button';
import DownloadIcon from '../icons/Download';
import {DeleteForever, RemoveRedEye as EyeIcon} from "@material-ui/icons";
import apiGet from "../api/apiGet";
import XIcon from "../icons/X";
import React, {useEffect, useRef} from "react";
import UploadIcon from "@material-ui/icons/Upload";
import apiPost from "../api/apiPost";
import {openBlob, setDownloadAttribute} from "../utils/blobUtils";

const DownloadAttachment = (props) => {
    const [state, setState] = React.useState(props.value ? 'uploaded' : 'empty');
    const fileInputRef = useRef(null);

    useEffect(() => {
        setState(props.value ? 'uploaded' : 'empty');
    }, [props.value]);

    const getPath = (action) => {
        if (props.model) {
            return `${props.model}/${props.node.data.id}/file/${props.colDef.field}/${action}`;
        } else {
            return `attachments/${props.node.data.id}/${action}`
        }
    }

    const downloadAttachment = () => {
        apiGet(getPath('download'), true).then(blob => {
            let tmp = props?.value?.toString().split('/');
            if(!tmp)
            {
                tmp = props?.node?.data?.attachment?.toString().split('/');
            }
            let filename = tmp ? tmp[tmp.length - 1]: 'download.docx';
            openBlob(blob, (link) => setDownloadAttribute(link, filename));
        });
    }

    const viewAttachment = () => {
        apiGet(getPath('download'), true).then(blob => {
            openBlob(blob);
        });
    }

    const deleteAttachment = () => {
        apiGet(getPath('delete'), false).then(() => {
            let deletedRow = props.node.data;
            if (!props.model) {
                props.node.gridApi.updateRowData({remove: [deletedRow]})  // It will update the row
            } else {
                setState('empty');
            }
        });
    }

    const onUpload = (e) => {
        let file = e.target.files[0];
        apiPost(getPath('upload'), [
                {key: 'file', value: file}
            ],
            (result) => {
                console.log("Success", result);
                setState('uploaded');
            },
            (error, response) => {
                alert(error);
                console.log(error, response);
            })
        console.log(file);
    }

    if (props.model && state === 'empty') {
        return (
            <>
                <input id={"passportInput"} onChange={onUpload} hidden ref={fileInputRef}
                       name="file" type="file"/>
                <Button
                    fullWidth
                    sx={{height: '100%'}}
                    color="primary"
                    type="button"
                    variant="outlined"
                    onClick={() => fileInputRef.current.click()}
                >
                    <UploadIcon/>
                </Button>
            </>
        );
    }

    return (
        <>
            <Button
                className="btn btn-default btn-sm delete-ingrid-but"
                onClick={downloadAttachment}
            > <DownloadIcon/>
            </Button>
            {/*<Button*/}
            {/*    className="btn btn-default btn-sm delete-ingrid-but"*/}
            {/*    onClick={viewAttachment}*/}
            {/*> <EyeIcon/>*/}
            {/*</Button>*/}
            {props.colDef.editable && <Button
                className="btn btn-default btn-sm delete-ingrid-but"
                onClick={deleteAttachment}
            > <DeleteForever/>
            </Button>}
        </>
    );

}

export default DownloadAttachment;
