import {Suspense, lazy} from 'react';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import sidebarColumns from "./pages/routes/sidebarColumns";
import sidebarMain from "./pages/routes/sidebarMain";
import DocumentTemplates from "./pages/settings/documents/documents";
import MainTableColumnsDefs from "./aggrid/MainTableColumnsDefs";
import getColumnDefinitions from "./services/getColumnDefinitions";

const Loadable = (Component) => (props) => (
    <Suspense fallback={<LoadingScreen/>}>
        <Component {...props} />
    </Suspense>
);

// Browse pages
const Users = Loadable(lazy(() => import('./pages/users')));

const OtherSettings = Loadable(lazy(() => import('./pages/settings/other_settings')));
const MailTemplates = Loadable(lazy(() => import('./pages/settings/mail_templates')));
const Translations = Loadable(lazy(() => import('./pages/settings/translations')));
const Permissions = Loadable(lazy(() => import('./pages/settings/permissions')));
const Roles = Loadable(lazy(() => import('./pages/settings/roles')));
const Columns = Loadable(lazy(() => import('./pages/settings/columns')));

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

let dashboard_children = [
    {
        path: 'users',
        element: <Users/>
    },
    {
        path: 'roles',
        element: <Roles/>
    },
    {
        path: 'permissions',
        element: <Permissions/>
    },
    {
        path: 'mail_templates',
        element: <MailTemplates/>,
    },
    {
        path: 'other_settings',
        element: <OtherSettings/>,
    },
    {
        path: 'document_templates',
        element: <DocumentTemplates/>
    }
];

for (const columnsRoute of sidebarColumns) {
    dashboard_children.push({
        element: <Columns model={columnsRoute.model}/>,
        path: `${columnsRoute.model}_columns`,
    });
}

for (const mainRoute of sidebarMain) {
    dashboard_children.push({
        element: mainRoute.element,
        path: mainRoute.path,
    });
}

const routes = [
    {
        path: 'authentication',
        children: [
            {
                path: 'login',
                element: (
                    <GuestGuard>
                        <Login/>
                    </GuestGuard>
                )
            },
            {
                path: 'login-unguarded',
                element: <Login/>
            },
            {
                path: 'password-recovery',
                element: <PasswordRecovery/>
            },
            {
                path: 'password-reset',
                element: <PasswordReset/>
            },
            {
                path: 'register',
                element: (
                    <GuestGuard>
                        <Register/>
                    </GuestGuard>
                )
            },
            {
                path: 'register-unguarded',
                element: <Register/>
            },
            {
                path: 'verify-code',
                element: <VerifyCode/>
            }
        ]
    },
    {
        path: '/',
        element: (
            <GuestGuard>
                <Login/>
            </GuestGuard>
        )
    },
    {
        path: 'dashboard',
        element: (
            <AuthGuard>
                <DashboardLayout/>
            </AuthGuard>
        ),
        children: dashboard_children
    },
    {
        path: '*',
        element: <MainLayout/>,
        children: [
            {
                path: '401',
                element: <AuthorizationRequired/>
            },
            {
                path: '404',
                element: <NotFound/>
            },
            {
                path: '500',
                element: <ServerError/>
            },
            {
                path: '*',
                element: <NotFound/>
            }
        ]
    }
];

export default routes;
