import FormWithValidation from "../../../components/form/FormWithValidation";
import {Button, Chip, Container, Divider, Grid, LinearProgress} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {memo, useEffect, useState} from "react";
import getColumnDefinitions from "../../../services/getColumnDefinitions";
import TabsWrapper from "../../../components/tabs/tabsWrapper";
import {getPriceFromFacility} from "../index";
import BasicAdd from "../../../components/grid/basicAdd";

export default memo((props) => {

    const {t} = useTranslation();

    const [cohabitants, setCohabitants] = useState([]);
    const [cars, setCars] = useState([]);

    const [tenantDefs, setTenantDefs] = useState(undefined);
    const [rentDefs, setRentDefs] = useState(undefined);

    const [tenantData, setTenantData] = useState([]);
    const [rentData, setRentData] = useState([]);

    const [tenantValid, setTenantValid] = useState(false);
    const [rentValid, setRentValid] = useState(false);

    useEffect(() => {
        let mounted = true

        getColumnDefinitions(t, 'clients', true).then((definitions) => {
            if (mounted) {
                for (const key of Object.keys(definitions)) {
                    tenantData[key] = [];
                    definitions[key] = definitions[key].filter((d) => d.name !== 'is_tenant');
                }
                setTenantDefs(definitions)
            }
        });

        getColumnDefinitions(t, 'rents', true).then((definitions) => {
            if (mounted) {
                for (const key of Object.keys(definitions)) {
                    rentData[key] = [];
                    definitions[key] = definitions[key].filter((d) => d.name !== 'tenant_id');
                }
                setRentDefs(definitions)
            }
        });

        return function cleanup() {
            mounted = false
        }
    }, [t])

    const [deleteDraft1, setDeleteDraft1] = useState(null);
    const [deleteDraft2, setDeleteDraft2] = useState(null);

    if (!tenantDefs || !rentDefs) {
        return <LinearProgress fullWidth sx={{minWidth: "200px"}}/>
    }

    let form1;
    if (Object.keys(tenantDefs).length > 1) {
        form1 = <TabsWrapper tabs={Object.keys(tenantDefs).map(key => {
            return {
                label: key,
                content: <Container sx={{mt: 2}}><FormWithValidation
                    data={tenantData[key]}
                    form={tenantDefs[key]}
                    getFunctionToDeleteDraft={(func) => {
                        setDeleteDraft1(() => func);
                    }}
                    onChange={(d, v) => {
                        setTenantData(d);
                        setTenantValid(v);
                    }}/></Container>
            }
        })}/>
    } else {
        form1 = <>
            <div style={{marginTop: '8px'}}>{t("Tenant")}</div>
            <Divider sx={{my: 2}}/><FormWithValidation
            data={tenantData['default']}
            form={tenantDefs['default']}
            getFunctionToDeleteDraft={(func) => {
                setDeleteDraft1(() => func);
            }}
            onChange={(d, v) => {
                setTenantData(d);
                setTenantValid(v);
            }}
        /></>
    }

    let form2;
    if (Object.keys(rentDefs).length > 1) {
        form2 = <TabsWrapper tabs={Object.keys(tenantDefs).map(key => {
            return {
                label: key,
                content: <Container sx={{mt: 2}}><FormWithValidation
                    data={rentData[key]}
                    form={rentDefs[key]}
                    handleChange={getPriceFromFacility}
                    getFunctionToDeleteDraft={(func) => {
                        setDeleteDraft2(() => func);
                    }}
                    onChange={(d, v) => {
                        setRentData(d);
                        setRentValid(v);
                    }}/></Container>
            }
        })}/>
    } else {
        form2 = <>
            <div style={{marginTop: '8px'}}>{t("Pronájem")}</div>
            <Divider sx={{my: 2}}/>
            <FormWithValidation
                data={rentData['default']}
                form={rentDefs['default']}
                handleChange={getPriceFromFacility}
                getFunctionToDeleteDraft={(func) => {
                    setDeleteDraft2(() => func);
                }}
                onChange={(d, v) => {
                    setRentData(d);
                    setRentValid(v);
                }}
            /></>
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Grid item xs={12}>
                    {form1}
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{my: 2}}/>
                    <Container sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                        {cohabitants.map((cohabitant, i) => {
                            return (
                                <Chip
                                    sx={{my: 1, mr: 2, fontSize: '1rem'}}
                                    label={cohabitant[0].value + " " + cohabitant[1].value + " " + cohabitant[2].value}
                                    variant="outlined" onDelete={() => {
                                    let copy = [...cohabitants];
                                    copy.splice(i, 1);
                                    setCohabitants(copy);
                                }}/>
                            )
                        })}
                        <div style={{width: '150px', marginTop: '6px'}}>
                            <BasicAdd title={"Přidat spolubydlicí"} model="cohabitants"
                                      presetValues={[{key: 'rent_id', value: 0}]}
                                      interceptAdd={(data) => {
                                          console.log(data);
                                          setCohabitants((prevState => {
                                              return [...prevState, data]
                                          }));
                                      }}/>
                        </div>
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{my: 2}}/>
                    <Container sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                        {cars.map((car, i) => {
                            return (
                                <Chip
                                    sx={{my: 1, mr: 2, fontSize: '1rem'}}
                                    label={car[0].value}
                                    variant="outlined" onDelete={() => {
                                    let copy = [...cars];
                                    copy.splice(i, 1);
                                    setCars(copy);
                                }}/>
                            )
                        })}
                        <div style={{width: '150px', marginTop: '6px'}}>
                            <BasicAdd title={"Přidat car"} model="cars"
                                      presetValues={[{key: 'rent_id', value: 0}]}
                                      interceptAdd={(data) => {
                                          console.log(data);
                                          setCars((prevState => {
                                              return [...prevState, data]
                                          }));
                                      }}/>
                        </div>
                    </Container>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                {form2}
            </Grid>
            <Grid item xs={12}>
                <Divider sx={{my: 2}}/>
                <Button fullWidth
                        onClick={() => {
                            if(rentData.find(d => d.key === 'facility_id')?.value?.toString() !== '94')
                            {
                                let facilityId = rentData.find((d) => d.key === 'facility_id').value;
                                let rents = JSON.parse(localStorage.getItem('rents')).filter(rent => !rent.ukonceni_najmu && rent.facility_id === facilityId);

                                if (rents) {
                                    let datesToCompare = {
                                        from: new Date(rentData.find(d => d.key === 'from_date').value),
                                        to: new Date(rentData.find(d => d.key === 'to_date').value)
                                    };

                                    for (const rent of rents) {
                                        let rentDuration = {to: new Date(rent.to_date), from: new Date(rent.from_date)}
                                        //if datesToCompare overlaps rentDuration return
                                        if (datesToCompare.from < rentDuration.to && datesToCompare.to > rentDuration.from) {
                                            console.log("Overlaps with", rent);
                                            alert(`Rents duration overlaps with ${rent.from_date}/${rent.to_date}`)
                                            return;
                                        }
                                    }
                                }
                            }
                            if(deleteDraft1){
                                deleteDraft1();
                            }
                            if(deleteDraft2){
                                deleteDraft2();
                            }
                            props.onSubmit(tenantData, rentData, cohabitants, cars);
                            props.onClose();
                        }}
                        variant="contained" color="primary"
                        disabled={!tenantValid || !rentValid}>
                    {t("Odeslat")}
                </Button>
            </Grid>
        </Grid>
    )
});