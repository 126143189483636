import {format} from "date-fns";
import apiGet from "../api/apiGet";

export function getName(i, model) {
    if (i?.toString() === "0") {
        return "Žádná data";
    } else if (!i) {
        return "";
    }

    if (model === 'rents') {
        let tenant = JSON.parse(localStorage.getItem(`clients_${i.tenant_id}`));
        let facility = JSON.parse(localStorage.getItem(`facilities_${i.facility_id}`));

        return `${getName(tenant, 'clients')} ${getName(facility, 'facilities')}`;
    }

    if (i.company_name) {
        return i.company_name;
    } else if (i.name && i.surname) {
        return i.name + ' ' + i.surname;
    } else if (i.name) {
        return i.name;
    } else if (i.surname) {
        return i.surname;
    } else if (i.jmeno_fyzlico && i.surname_fyzlico) {
        return i.jmeno_fyzlico + ' ' + i.surname_fyzlico;
    } else if (i.jmeno_fyzlico) {
        return i.jmeno_fyzlico;
    } else if (i.surname_fyzlico) {
        return i.surname_fyzlico;
    } else if (i.invoice_number) {
        return i.invoice_number;
    } else if (i.name_cs) {
        return i.name_cs;
    } else {
        console.log("No name found for ", i, 'of model', model);
        return `id('${i.id}') "Název nenalezen"`;
    }
}

export const strComparator = (a, b) => {
    if (a === null) {
        return -1;
    }
    if (b === null) {
        return 1;
    }
    a = a.toString();
    b = b.toString();
    let num1 = a.match(/\d+/g);
    let num2 = b.match(/\d+/g);
    if (num1) num1 = parseInt(num1[0]);
    if (num2) num2 = parseInt(num2[0]);

    let text1 = a.match(/[a-zA-Z]+/g);
    let text2 = b.match(/[a-zA-Z]+/g);
    if (text1) text1 = text1[0];
    if (text2) text2 = text2[0];

    if (text1 < text2) {
        return -1;
    } else if (text1 > text2) {
        return 1;
    } else {
        if (num1 && num2) {
            return num1 - num2;
        } else {
            return a < b ? -1 : a > b ? 1 : 0;
        }
    }
}

export default async function getColumnDefinitions(t, model, form = false, processDefinitions = (a) => a) {
    let definitions = sessionStorage.getItem(`${model}_columns`);

    if (definitions && definitions !== "undefined") {
        definitions = JSON.parse(definitions);
    } else {
        definitions = await apiGet(`${model}/columns_definitions`)
            .then(response => response.data);
        sessionStorage.setItem(`${model}_columns`, JSON.stringify(definitions));
    }

    if (!definitions || definitions.length === 0) return [];
    if (form) {
        definitions = definitions.filter(item => item.in_form).sort((a, b) => a.order - b.order).map(def => {
            let type = def.column_type;
            let regex = "";
            let size = def.size ?? 4;
            let data_array = undefined;
            let processValue = undefined;
            let defaultAllowedRegex = undefined;
            let isDisabled = def.disabled ? () => true : undefined;

            if (def.field === 'down_payment_invoice_id') {
                isDisabled = (_, data) => {
                    let val = data.invoice_type.value?.toString();
                    return !!(val !== '1' && val !== '3');
                };
            }

            if (def.field === 'danovy_doklad_invoice') {
                isDisabled = (_, data) => {
                    let val = data.invoice_type.value?.toString();
                    return val !== '1';
                };
            }

            if (def.field === "rent_month") {
                isDisabled = (_, data) => {
                    return (data.rent_year?.value ?? '') === '';
                };
            }

            if (def.column_type === "text") {
                type = "text";
                regex = /^.*$/;
            } else if (def.column_type === "decimal") {
                type = "text";
                regex = /^-?((\d*)|(\d+\.\d+))$/
                defaultAllowedRegex = /^-?((\d*)|(\d+\.\d*))$/;
            } else if (def.column_type === "integer") {
                type = "text";
                regex = /^-?\d*$/
                defaultAllowedRegex = /^-?\d*$/;
            } else if (def.column_type === "select" || def.column_type === "multiselect") {
                if (def.data_array) {
                    if (def.data_array !== 'custom') {
                        data_array = JSON.parse(localStorage.getItem(def.data_array));
                    } else {
                        data_array = JSON.parse(def.data);
                    }
                    if (def.data_array === "clients" && model !== "issued_invoices") {
                        data_array = data_array.filter(i => i.is_tenant === 1);
                    }
                    if (def.field === 'down_payment_invoice_id') {
                        data_array = data_array.filter(i => i.invoice_type?.toString() === '2');
                    }
                    if (def.field === 'danovy_doklad_invoice') {
                        data_array = data_array.filter(i => i.invoice_type?.toString() === '3');
                    }
                } else {
                    data_array = [];
                }
                data_array = data_array.filter(item => !item.archived && !item.deleted).sort((a, b) => strComparator(getName(a, def.data_array), getName(b, def.data_array)));
            }

            if (def.field === 'swift') {
                processValue = (value) => value.toUpperCase();
            }

            let formula = '';
            if (def.formula) {
                formula = def.formula;
                let result;
                while ((result = /{[^{]+}/.exec(formula)) !== null) {
                    let field = result[0].replace(/[{}]/g, '');
                    let replace = "";
                    if (field === "vat_percentage") {
                        replace = `((JSON.parse(localStorage.getItem('dph'))?.find(i => i?.id === data['${field}']?.value)?.name) || 0)`;
                    } else {
                        if (def.column_type === 'integer') {
                            replace = `(parseInt(data['${field}']?.value) || 0)`;
                        } else if (def.column_type === 'decimal') {
                            replace = `(parseFloat(data['${field}']?.value) || 0)`;
                        } else if (def.column_type === 'text') {
                            replace = `(data['${field}']?.value.toString() || '')`;
                        }
                    }
                    formula = formula.replace(result[0], replace);
                }

                // while ((result = /[$]([^$])+[$]/.exec(formula)) !== null) {
                //     let source = result[1];
                //     let replace = "";
                //     let data = `externalData.find(i => i.key == '${source}')?.data`;
                //     if(def.column_type === 'integer'){
                //         replace = `(parseInt(${data}) || 0)`;
                //     } else if (def.column_type === 'decimal'){
                //         replace = `(parseFloat(${data}) || 0)`;
                //     } else if (def.column_type === 'text'){
                //         replace = `(${data}.toString() || '')`;
                //     }
                //     formula = formula.replace(result[0], replace);
                // }

                while ((result = /\$([^\.]+)\.sum\(([^\(]+)\)/.exec(formula)) !== null) {
                    let source = result[1];
                    let field = result[2];
                    formula = formula.replace(result[0],
                        `externalData.find(i => i.key == '${source}')
                        .data.map(a => {
                            let val = parseFloat(a.find((b) => b.key == '${field}')?.value);
                            if(isNaN(val)) 
                                return 0; 
                            return val;
                        }).reduce((sum, val) => sum + val, 0)`);
                }
            }
            return {
                name: (def.column_type === 'multifile') ? def.field + '[]' : def.field,
                label: t(def.name),
                editable: def.editable,
                isDisabled: isDisabled,
                formula: formula,
                size: size,
                required: def.required !== 0,
                type: type,
                regex: regex,
                data_array: data_array,
                data_array_model: def.data_array,
                order: def.order,
                tags: def.tags,
                groups: def.groups,
                processValue: processValue,
                allowedRegex: def.allowed_regex ? new RegExp(def.allowed_regex) : defaultAllowedRegex,
                defaultValue: def.default_value
            }
        });

        definitions = processDefinitions(definitions);

        let grouped = {};
        for (const definition of definitions) {
            if (definition.tags) {
                for (const tag of definition.tags.split(',')) {
                    if (!grouped[tag]) {
                        grouped[tag] = [];
                    }
                    grouped[tag].push(definition);
                }
            }
        }

        for (const definition of definitions) {
            if (!definition.tags) {
                for (const groupedKey in grouped) {
                    grouped[groupedKey].push(definition);
                }
            }
        }

        if (Object.keys(grouped).length === 0) return {default: definitions};
        return grouped;
    } else {
        definitions = definitions.map(def => {
            let cellEditor, valueFormatter, cellEditorParams, data_array, filter, comparator, filterParams;
            switch (def.column_type) {
                case "boolean":
                    return {
                        headerName: def.name,
                        editable: def.editable,
                        field: def.field,
                        column_type: def.column_type,
                        cellRenderer: "CheckboxRenderer",
                        cellRendererParams: {
                            disabled: def.disabled,
                            editable: !def.disabled,
                        },
                        suppressMenu: true,
                    }
                case "select":
                    if (def.data_array) {
                        if (def.data_array !== 'custom') {
                            data_array = JSON.parse(localStorage.getItem(def.data_array));
                        } else {
                            data_array = JSON.parse(def.data);
                        }
                        if (def.data_array === "clients" && model !== "issued_invoices") {
                            data_array = data_array.filter(i => i.is_tenant === 1);
                        }
                    }
                    cellEditor = "Select";
                    comparator = (a, b) => {
                        let items = JSON.parse(localStorage.getItem(def.data_array));
                        if (!items) {
                            return strComparator(a, b);
                        }
                        a = items.find(i => i?.id === a);
                        b = items.find(i => i?.id === b);
                        return strComparator(getName(a, def.data_array), getName(b, def.data_array))
                    };
                    cellEditorParams = {
						model: model,
                        data_array: data_array,
                        data_array_model: def.data_array,
                    };
                    valueFormatter = function (params) {
                        let val;
                        if (val?.toString() === "0") return getName(0, null);
                        if (def.data_array !== "invoice_item_names" && def.data_array !== "additional_texts") {
                            val = data_array.find(item => item?.id?.toString() === params?.value?.toString()) ?? "";
                        } else {
                            return params.value?.toString() ?? "";
                        }
                        return getName(val, def.data_array);
                    }
                    filter = 'agSetColumnFilter';
                    filterParams = {
                        valueFormatter: valueFormatter
                    }
                    break;
                case 'multiselect':
                    if (def.data_array) {
                        if (def.data_array !== 'custom') {
                            data_array = JSON.parse(localStorage.getItem(def.data_array));
                        } else {
                            data_array = JSON.parse(def.data);
                        }
                    }
                    cellEditor = "MultiSelect";
                    cellEditorParams = {
                        data_array: data_array,
                        data_array_model: def.data_array,
                    };
                    valueFormatter = function (params) {
                        let ids = params?.value?.toString().split('//');
                        let result = "";
                        if (!ids) return "";
                        for (const id of ids) {
                            if (id?.toString() === "0") {
                                result += getName(0, null) + ", ";
                                continue;
                            }

                            result += getName(data_array.find(item => item?.id?.toString() === id), def.data_array) + ", ";
                            if (result.length > 100) {
                                result = result.substring(0, result.length - 2) + "...";
                                break;
                            }
                        }
                        return result.substring(0, result.length - 2);
                    }
                    filter = 'agTextColumnFilter';
                    break;
                case "file":
                    return {
                        field: def.field,
                        headerName: def.name,
                        editable: !def.disabled,
                        column_type: def.column_type,
                        cellRenderer: "DownloadAttachment",
                        cellRendererParams: {model: model},
                        minWidth: 150
                    }
                case "multifile":
                    return {
                        field: def.field,
                        headerName: def.name,
                        editable: !def.disabled,
                        column_type: def.column_type,
                        cellRenderer: "OpenInNewRenderer",
                        minWidth: 150
                    }
                case "date":
                    cellEditor = "DatePicker";
                    valueFormatter = (params) => {
                        return (params.value && params.value !== "0000-00-00") ? format(new Date(params.value), 'dd.MM.yyyy') : '';
                    }
                    filter = 'agDateColumnFilter';
                    filterParams = {
                        comparator: (aDate, b) => {
                            if (aDate && b) {
                                let bDate = new Date(b);
                                bDate.setHours(0, 0, 0, 0);
                                return aDate.getTime() - bDate.getTime();
                            }
                            if (aDate) {
                                return 1;
                            }
                            return -1;
                        }
                    }
                    comparator = (a, b) => {
                        if (a && b) {
                            let aDate = new Date(a);
                            let bDate = new Date(b);
                            bDate.setHours(0, 0, 0, 0);
                            return aDate.getTime() - bDate.getTime();
                        }
                        if (a) {
                            return 1;
                        }
                        return -1;
                    }
                    break;
                default:
                    cellEditor = "";
                    valueFormatter = "";
                    comparator = strComparator;
            }
            if (def.column_type === "text") {
                filter = 'agTextColumnFilter';
            } else if (def.column_type === "integer" || def.column_type === "decimal") {
                filter = 'agNumberColumnFilter';
            }

            if (model === 'wire_transfers' && def.field === 'total_amount') {
                valueFormatter = (params) => {
                    return Math.round(params.value) / 100;
                }
            }

            return {
                headerName: def.name,
                editable: !def.disabled,
                field: def.field,
                cellEditor: cellEditor,
                valueFormatter: valueFormatter,
                column_type: def.column_type,
                cellEditorParams: cellEditorParams,
                filter: filter,
                filterParams: filterParams,
                comparator: comparator,
                save_history: def.save_history,
            }
        })
        return processDefinitions(definitions);
    }
}