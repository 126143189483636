import ModalWithButton from "../../../components/modal/ModalWithButton";
import RentFormWithTenant from "./rentFormWithTenant";
import apiPost from "../../../api/apiPost";
import {useTranslation} from "react-i18next";
import useFilter from "../../../hooks/useFilter";

export default function AddRentWithTenant() {
    const {t} = useTranslation();
    const filter = useFilter();

    const handleSubmit = (tenant, rent, cohabitants, cars) => {
        apiPost("clients/add", tenant.concat({key: 'is_tenant', value: 1}),
            async (result1) => {
                console.log("Tenant added successfully", result1);
                apiPost("rents/add", rent.concat({key: 'tenant_id', value: result1.data.id}),
                    async (result2) => {
                        for (const cohabitant of cohabitants) {
                            await apiPost("cohabitants/add", cohabitant.concat({key: 'rent_id', value: result2.data.id}),
                                () => {
                                    alert(`Spolubydlicí ${cohabitant[0].value + cohabitant[1].value} přidán úspěšně`);
                                    return true;
                                },
                                (error, response) => {
                                    alert(`Spolubydlicí ${cohabitant[0].value + cohabitant[1].value} nebyl přidán. Chyba.`);
                                    console.log(error, response);
                                    return false;
                                })
                        }
                        for (const car of cars) {
                            await apiPost("cars/add", car.concat({key: 'rent_id', value: result2.data.id}),
                                () => {
                                    alert(`Car ${car[0].value} přidán úspěšně`);
                                    return true;
                                },
                                (error, response) => {
                                    alert(`Car ${car[0].value} nebyl přidán. Chyba.`);
                                    console.log(error, response);
                                    return false;
                                })
                        }
                        console.log("Cohabitants added successfully");
                        console.log("Tenant added successfully", result1);

                        alert(t("Položka přidána úspěšně"));
                        localStorage.setItem('public_settings_version', '0');
                        localStorage.setItem('private_settings_version', '0');
                        sessionStorage.clear();
                    },
                    (error, response) => {
                        alert(error);
                        console.log(error, response);
                    })
            },
            (error, response) => {
                alert(error);
                console.log(error, response);
            })
    }

    if(filter.pronajimatel !== 1)
    {
        return null;
    }

    let permissions = JSON.parse(localStorage.getItem('my_permissions')) ?? [];

    if (!permissions.includes("rents.add") || !permissions.includes("clients.add")) {
        return null;
    }

    return (
        <ModalWithButton title={"Nový pronájem"} size={'md'} buttonProps={{sx: {maxWidth: '150px', mx: 2}}}>
            <RentFormWithTenant onSubmit={handleSubmit}/>
        </ModalWithButton>
    )
}