import GridWrapper from "./gridWrapper";
import {useEffect, useState} from "react";
import getColumnDefinitions from "../../services/getColumnDefinitions";
import {useTranslation} from "react-i18next";
import apiPost from "../../api/apiPost";
import {LinearProgress} from "@material-ui/core";

export default function Details({title, item, model, editable = true}) {
    const {t} = useTranslation();
    const [data, setData] = useState([]);
    const [definitions, setDefinitions] = useState([]);

    useEffect(() => {
        getColumnDefinitions(t, model).then((definitions) => {
            setDefinitions(definitions);
        });
    }, [model, t]);

    useEffect(() => {
        setData(definitions.map(def => ({key: def.headerName, value: item[def.field], field: def.field})));
    }, [definitions, item])

    if (definitions.length === 0) {
        return <LinearProgress fullWidth/>
    }

    return (
        <GridWrapper
            helmet={`Dashboard: ${title} | Belo estate`}
            title={title}
            colDefs={[
                {
                    headerName: 'Field',
                    field: 'key',
                    editable: false,
                    suppressMenu: true,
                }, {
                    headerName: 'Value',
                    field: 'value',
                    editable: (params) => {
                        if (!editable) return false;
                        let def = definitions.find(def => def.field === params.data.field);
                        return def?.editable;
                    },
                    suppressMenu: true,
                    valueGetter: (params) => {
                        let def = definitions.find(def => def.field === params.data.field);
                        return def?.valueGetter ? def.valueGetter(params) : params.data.value;
                    },
                    valueFormatter: (params) => {
                        let def = definitions.find(def => def.field === params.data.field);
                        return def?.valueFormatter ? def.valueFormatter(params) : params.data.value;
                    },
                    cellEditorSelector: params => {
                        let def = definitions.find(def => def.field === params.data.field);
                        if (!def) return undefined;
                        return {
                            component: def.cellEditor,
                            params: def.cellEditorParams,
                        }
                    },
                    cellRendererSelector: params => {
                        let def = definitions.find(def => def.field === params.data.field);
                        if (!def) return undefined;
                        if (!editable && def.cellRendererParams) {
                            def.cellRendererParams.disabled = true;
                            def.cellRendererParams.editable = false;
                        }
                        return {
                            component: def.cellRenderer,
                            params: def.cellRendererParams,
                        };
                    }
                },
            ]}
            data={data}
            interceptChange={e => {
                console.log(e);
                let field = e.data.field;
                let newValue = e.newValue;
                let id = item.id;

                if (newValue === true) {
                    newValue = 1;
                } else if (newValue === false) {
                    newValue = 0;
                }

                console.log(field, newValue, id);

                apiPost(`${model}/update`,
                    [
                        {key: 'id', value: id},
                        {key: 'field', value: field},
                        {key: 'value', value: newValue}],
                    (response) => {
                        console.log("Success: ", response.data);
                    },
                    (error, response) => {
                        alert("ERROR: " + error);
                        console.error("Response with ERROR: ", response);
                    });
            }}
        >
        </GridWrapper>
    )
}