import ModalWithButton from "../../../components/modal/ModalWithButton";
import RentForm from "./rentForm";

export default function AddRent({onAdd, presetValues}) {
    let permissions = JSON.parse(localStorage.getItem('my_permissions')) ?? [];

    if (!permissions.includes("rents.add")) {
        return null;
    }

    return (
        <ModalWithButton title={"Nový pronájem"} size={'md'} buttonProps={{sx: {px: 2, ml: 2}}}>
            <RentForm onAdd={onAdd} presetValues={presetValues}/>
        </ModalWithButton>
    )
}