import ModalWithButton from "../modal/ModalWithButton";
import FormWithSubmit from "../../components/form/FormWithSubmit";
import apiPost from "../../api/apiPost";
import getColumnDefinitions from "../../services/getColumnDefinitions";
import {memo, useEffect, useState} from "react";
import {LinearProgress} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import TabsWrapper from "../tabs/tabsWrapper";


export default function BasicAdd({onAdd, model, presetValues, defaultValues, handleChange, interceptAdd, title, buttonProps, columnDefinitionsHandler}) {

    const handleAdd = (values) => {
        if(interceptAdd) {
            interceptAdd(presetValues ? values.concat(presetValues) : values);
            return;
        }
        apiPost(model + "/add", presetValues ? values.concat(presetValues) : values,
            (result) => {
                console.log("Success", result);
                onAdd(result.data);
            },
            (error, response) => {
                alert(error);
                console.log(error, response);
            })
    };

    const [columnDefinitions, setColumnDefinitions] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const {t} = useTranslation();

    useEffect(() => {
        let mounted = true

        if(!columnDefinitions) {
            getColumnDefinitions(t, model, true, (definitions) => definitions.filter(def => !presetValues?.find(val => def.name === val.key)))
                .then((definitions) => {
                    if (mounted) {
						
						if( columnDefinitionsHandler ){
						
							definitions = columnDefinitionsHandler( definitions );
						
						}
						
                        setColumnDefinitions(definitions)
                        setIsLoading(false);
                    }
                });
        }
        return function cleanup() {
            mounted = false
        }
    }, [presetValues, model, t, columnDefinitions])

    if (isLoading) {
        return (
            <ModalWithButton title={"Add " + model}>
                <LinearProgress fullWidth sx={{minWidth: "200px"}}/>
            </ModalWithButton>)
    }

    if (!columnDefinitions) {
        alert("No column definitions found for " + model);
    }

    let permissions = JSON.parse(localStorage.getItem('my_permissions')) ?? [];

    if (!permissions.includes(model + ".add")) {
        return null;
    }

    let body;

    if (Object.keys(columnDefinitions).length > 1) {
        body = <TabsWrapper tabs={Object.keys(columnDefinitions).map(key => {
            return {
                label: key,
                content: <FormWithSubmit
                    sx={{mt: 2}}
                    form={columnDefinitions[key]}
                    onSubmit={handleAdd}
                    defaultValues={defaultValues}
                    handleChange={handleChange}
                    submitText="Odeslat"/>
            }
        })}/>
    } else {
        body = <FormWithSubmit
            form={columnDefinitions['default']}
            onSubmit={handleAdd}
            handleChange={handleChange}
            defaultValues={defaultValues}
            submitText="Odeslat"
        />
    }

    return (
        <ModalWithButton buttonProps={buttonProps} title={title ?? "Přidat"} size={'md'} onOpen={() => {
            setColumnDefinitions(null);
            setIsLoading(true);
        }}>
            {body}
        </ModalWithButton>
    )
}