import BasicAdd from "../../../components/grid/basicAdd";
import GridWrapper from "../../../components/grid/gridWrapper";

export default function Cars({tab, presetValues, handleRowData}) {

    return (
            <GridWrapper
                style={tab ? {mt: 2} : null}
                helmet="Dashboard: Cars List | Belo estate"
                title="Cars"
                model="cars"
                filter={handleRowData}>
                <BasicAdd model={"cars"} presetValues={presetValues}/>
            </GridWrapper>
    )

}