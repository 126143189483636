import React from "react";

export default function addPropsToChildren(children, props){
    if(!children) return null;
    let result;
    if (Array.isArray(children)) {
        result = children.map((child) => React.cloneElement(child, props))
    } else {
        result = React.cloneElement(children, props)
    }
    return result;
}