import {Chip} from "@material-ui/core";

export default function LastUpdated({rowData})
{
    let lastUpdated;
    if (rowData[0]==null) {
        lastUpdated= new Date('1995-12-17T03:24:00');
    } else {
        lastUpdated = new Date(rowData[0].updated_at);
    }

    for (const item of rowData) {
        let date = new Date(item.updated_at);
        if(date > lastUpdated) {
            lastUpdated = date;
        }
    }
    return (
        <Chip sx={{height: '100%', p: 1, ml:1}} label={"Poslední obnovení: " + lastUpdated.toLocaleString()} variant="filled"/>
    )
}