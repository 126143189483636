import BasicAdd from "../../../components/grid/basicAdd";
import GridWrapper from "../../../components/grid/gridWrapper";

export default function Cohabitants({tab, presetValues, handleRowData}) {

    return (
            <GridWrapper
                style={tab ? {mt: 2} : null}
                helmet="Dashboard: Cohabitants List | Belo estate"
                title="Spolubydlicí"
                model="cohabitants"
                filter={handleRowData}>
                <BasicAdd model={"cohabitants"} presetValues={presetValues}/>
            </GridWrapper>
    )

}