import {useTranslation} from 'react-i18next';


const Columns = (table, noneditable) => {
    const {t} = useTranslation();
    var locale = 'cs';

    if (t('cs') == 'ru') locale = 'ru';


    var columnDefs = [];

    var visible = JSON.parse(localStorage.getItem('my_roles_meta')).find(refData => refData['name'] == table + '-visible').value.split('//');
    var editable = JSON.parse(localStorage.getItem('my_roles_meta')).find(refData => refData['name'] == table + '-editable').value.split('//');
    if (noneditable) editable = [];
    JSON.parse(localStorage.getItem('columns')).map((c) => {
        if (c.model !== table) return false;
        if (!visible.includes(c.field)) return true;
        var obj = {};
        if (editable.includes(c.field) && c.locked != 1) obj.editable = function (params) {
            if (params.data && params.data.id) {
                return true;
            }
            /*

            else {
                if (table==='loadings' && (c.field==='loading_qty' || c.field==='product_type' || c.field==='net_material_qty' ||
                    c.field==='gross_material_qty' || c.field==='material_units_qty' || c.field==='material_units_description')) return false;
            }
            */
            return true;
        }
        obj.headerName = t(c['name']);
        obj.field = c.field;

        if (c.column_type == 'integer') {
            obj.filter = 'agNumberColumnFilter';
            // validating integers

            obj.valueSetter = function (params) {
                if (params.data && params.data.id) {
                    params.data[c.field] = parseInt(params.newValue);
                } else {
                    params.node.data.groupedvalue = parseInt(params.newValue);
                    params.node.data.groupedfield = c.field;
                }
            }
        }
        if (c.column_type == 'decimal') {
            obj.filter = 'agNumberColumnFilter';
            // validating decimals
            obj.valueSetter = function (params) {
                if (params.data && params.data.id) {
                    params.data[c.field] = parseFloat(params.newValue);
                } else {
                    params.node.data.groupedvalue = parseFloat(params.newValue);
                    params.node.data.groupedfield = c.field;

                }
            }
        }
        if (c.column_type == 'text') {
            obj.filter = 'agTextColumnFilter';
            obj.valueSetter = function (params) {
                if (params.data && params.data.id) {
                    params.data[c.field] = params.newValue;
                } else {
                    params.node.data.groupedvalue = params.newValue;
                    params.node.data.groupedfield = c.field;
                }
            }
        }
        if (c.column_type == 'text' || c.column_type == 'integer' || c.column_type == 'decimal') {
            obj.valueGetter = function (params) {

                if (params.data && params.data.id) {
                    if (params.data[c.field] == 'ZOBRAZIT') return params.data[c.field];
                    if (params.data[c.field] === null) return '';
                    return params.data[c.field];
                } else {
                    var ret = '';
                    var vals = [];
                    var sum = 0;
                    try {
                        params.node.allLeafChildren.map((e) => {
                            if (vals.includes(e.data[c.field])) return true;
                            ret += e.data[c.field] + ', ';
                            sum = sum + parseFloat(e.data[c.field]);
                            vals.push(e.data[c.field]);
                        });
                    } catch (err) {
                        console.log(params);
                        console.log(err);
                    }


                    if (ret.slice(0, -2) === 'null') return '';
                    return ret.slice(0, -2);
                }

            }
        }
        if (c.column_type == 'boolean') {
            obj.filter = 'agSetColumnFilter';

            obj.cellEditor = 'agRichSelectCellEditor';


            obj.cellEditorParams = {
                values: JSON.parse(localStorage.getItem('booleans')).map(e => e['name']),
                formatValue: function (params) {
                    return t(params)
                }
            }
            obj.valueSetter = function (params) {
                if (params.data && params.data.id) {
                    params.data[c.field] = JSON.parse(localStorage.getItem('booleans')).find(refData => refData['name'] == params.newValue).id;
                    return true;
                } else {
                    params.node.data.groupedvalue = JSON.parse(localStorage.getItem('booleans')).find(refData => refData['name_' + locale] == params.newValue).id;
                    params.node.data.groupedfield = c.field;
                }
            }
            obj.valueGetter = function (params) {
                // console.log(params);
                if (params.data && params.data.id) {
                    if (params.data[c.field] == 'ZOBRAZIT') return params.data[c.field];
                    if (params.data[c.field] == null || params.data[c.field] == '') return '';
                    try {
                        return t(JSON.parse(localStorage.getItem('booleans')).find(refData => String(refData.id) === String(params.data[c.field]))['name']);
                    } catch (err) {
                        console.log(params.data[c.field])
                        console.log(c.field)
                        console.log(err);
                        return '';
                    }
                } else {

                    var ret = '';
                    var vals = [];
                    params.node.allLeafChildren.map((e) => {
                        if (vals.includes(e.data[c.field])) return true;
                        try {
                            ret += JSON.parse(localStorage.getItem('booleans')).find(refData => String(refData.id) === String(e.data[c.field]))['name'] + ', ';
                            vals.push(e.data[c.field]);
                        } catch (err) {
                            console.log(e.data[c.field])
                            console.log(c.field)
                            console.log(err);
                            return '';
                        }
                    });
                    return ret.slice(0, -2);
                }
            }

        }
        if (c.column_type == 'file' || c.column_type == 'multifile') {
            obj.cellEditor = 'FileEditor';
            obj.cellEditorParams = {
                model: table, field: c.field
            }

            obj.valueFormatter = function (params) {

                if (params.value != null && params.value != '' && params.value != 'NULL') {
                    return "OK"
                } else {
                    return '';
                }
            }
        }
        if (c.column_type == 'select') {
            obj.filter = 'agSetColumnFilter';
            if (c.field === 'spouse_id') {
                obj.filter = 'agSetColumnFilter';
                obj.valueGetter = function (params) {
                    let employee = params.data;
                    if (employee.spouse_id) {
                        return employee.spouse_fullname;
                    }
                }
            } else {
                var values = [];
                var tmp_values = [];
                if (c.data_array == 'custom') {
                    if (c.data != null && c.data != '') values = JSON.parse(c.data);
                } else if (c.data_array == '' || c.data_array == null || c.data_array == 'none') {
                    //
                } else {
                    values = JSON.parse(localStorage.getItem(c.data_array));
                }

                // checking if values are allowed
                var allowed_options = [];
                JSON.parse(localStorage.getItem('my_users_meta')).map((e) => {
                    if (e.name == table + '/columns-' + c.field) {
                        allowed_options = e.value.split('//');
                    }
                });
                try {
                    values.map((e) => {
                        if (allowed_options.includes(e.id.toString()) || allowed_options.length == 0 || (allowed_options.length == 1 && allowed_options[0] == '')) {
                            tmp_values.push(e);
                        }
                    });
                } catch (err) {
                    console.log(values);
                    console.log(c);
                }
                // end of allowed values check

                values = tmp_values;
                values.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

                //   console.log(values);


                if ((table == 'agency_accommodations' && c.field == 'pokoj')
                    || (table == 'assignments' && c.field == 'pozice_koordinator')
                    || (table == 'assignments' && c.field == 'pozice_administrativa')
                ) {
                    obj.cellEditor = 'Select';
                } else {

                    obj.cellEditor = 'agRichSelectCellEditor';

                    obj.cellEditorParams = {
                        values: values.map(e => e['name']),
                        formatValue: function (params) {
                            return t(params)
                        }
                    }
                    obj.valueSetter = function (params) {
                        if (params.data && params.data.id) {
                            try {
                                params.data[c.field] = values.find(refData => refData['name'] == params.newValue).id;
                                return true;
                            } catch (err) {
                                console.log(err);
                                return true;
                            }
                        } else {
                            try {
                                params.node.data.groupedvalue = values.find(refData => refData['name'] == params.newValue).id;
                                params.node.data.groupedfield = c.field;
                                return true;
                            } catch (err) {
                                console.log(err);
                                return true;
                            }
                        }
                    }
                }


                obj.valueGetter = function (params) {
                    if (params.data && params.data.id) {

                        if (params.data[c.field] == 'ZOBRAZIT') return params.data[c.field];

                        if (params.data[c.field] === null || params.data[c.field] === '') return '';
                        try {
                            return t(values.find(refData => String(refData.id) === String(params.data[c.field]))['name']);
                        } catch (err) {
                            console.log(params);
                            console.log(params.data);
                            console.log(c);
                            console.log(params.data[c.field]);
                            console.log(values);
                            console.log(err);
                            return '';
                        }
                    } else {
                        // for grouped rows
                        var ret = '';
                        var vals = [];
                        params.node.allLeafChildren.map((e) => {
                            if (e.data[c.field] === null || e.data[c.field] === '' || vals.includes(e.data[c.field])) return true;
                            try {
                                ret += t(values.find(refData => String(refData.id) === String(e.data[c.field]))['name']) + ', ';
                                vals.push(e.data[c.field]);
                            } catch (err) {
                                console.log(c);
                                console.log(e);
                                console.log(e.data[c.field]);
                                console.log(values);
                                console.log(err);
                                return '';
                            }
                        });
                        return ret.slice(0, -2);
                    }
                }
            }
        }
        if (c.column_type == 'multiselect') {
            obj.filter = 'agSetColumnFilter';
            obj.cellEditor = 'MultiSelectSimple';
            obj.cellEditorParams = {
                gridType: table
            };
            var values = [];
            if (c.data_array == 'custom') {
                if (c.data != null && c.data != '') values = JSON.parse(c.data);
            } else if (c.data_array == '' || c.data_array == null || c.data_array == 'none') {
                //
            } else {
                values = JSON.parse(localStorage.getItem(c.data_array));
            }


            obj.valueGetter = function (params) {
                if (params.data && params.data.id) {
                    if (params.data[c.field] == 'ZOBRAZIT') return params.data[c.field];
                    if (params.data[c.field] == null || params.data[c.field] == '') return '';
                    var res = '';
                    params.data[c.field].split('//').map((e) => {
                        try {
                            res += t(values.find(refData => String(refData.id) === String(e))['name']) + '//';
                        } catch (err) {
                            console.log(err);
                        }
                    });

                    return res.slice(0, -2);
                } else {
                    // for grouped rows
                    var ret = '';
                    var vals = [];
                    params.node.allLeafChildren.map((e) => {
                        if (e.data[c.field] == null || e.data[c.field] == '' || vals.includes(e.data[c.field])) return true;
                        var res = '';
                        e.data[c.field].split('//').map((i) => {
                            try {
                                res += t(values.find(refData => String(refData.id) === String(i))['name']) + ', ';
                            } catch (err) {
                                console.log(err);
                            }
                        });

                        ret += res.slice(0, -2) + ', ';
                        vals.push(e.data[c.field]);
                    });
                    return ret.slice(0, -2);
                }
            }
        }
        if (c.column_type == 'date') {
            obj.filter = 'agDateColumnFilter';
            obj.cellEditor = 'DatePicker';
            obj.cellEditorParams = {model: table};

            obj.valueSetter = function (params) {
                if (!params) return false;

                if (params.data && params.data.id) {

                    params.data[c.field] = params.newValue;
                    return true;

                } else {
                    params.node.data.groupedvalue = params.newValue.split(".")[2] + '-' + params.newValue.split(".")[1] + '-' + params.newValue.split(".")[0];
                    params.node.data.groupedfield = c.field;
                }

            };

            obj.valueGetter = function (params) {
                //console.log(params);
                if (params.data && params.data.id) {
                    if (params.data[c.field] == 'ZOBRAZIT') return params.data[c.field];
                    if (params.data[c.field] == null || params.data[c.field] == '' || params.data[c.field] == '1970-01-01' || params.data[c.field] == '0000-00-00') return '';
                    var d = new Date(params.data[c.field]);
                    var month = d.getMonth() + 1;
                    return d.getDate() + '.' + month + '.' + d.getFullYear();
                } else if (typeof params.node.allLeafChildren != 'undefined') {
                    var ret = '';
                    var vals = [];
                    params.node.allLeafChildren.map((e) => {
                        if (e.data[c.field] == null || e.data[c.field] == '' || vals.includes(e.data[c.field])) return '';
                        var d = new Date(e.data[c.field]);
                        var month = d.getMonth() + 1;
                        ret += d.getDate() + '.' + month + '.' + d.getFullYear() + ', ';
                        vals.push(e.data[c.field]);
                    });

                    return ret.slice(0, -2);
                }
            }
        }
        if (c.column_type == 'datetime') {
            obj.filter = 'agDateColumnFilter';
            obj.cellEditor = 'DateTimePicker';

            obj.valueSetter = function (params) {
                var d = new Date(Date.parse(params.newValue));
                if (params.data && params.data.id) {

                    params.data[c.field] = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + " "
                        + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
                    return true;
                } else {
                    params.node.data.groupedvalue = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + " "
                        + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
                    params.node.data.groupedfield = c.field;
                }
            };

            obj.valueGetter = function (params) {
                if (params.data && params.data.id) {
                    if (params.data[c.field] == 'ZOBRAZIT') return params.data[c.field];
                    if (params.data[c.field] == null || params.data[c.field] == '') return '';
                    var d = new Date(params.data[c.field]);
                    return ("0" + d.getDate()).slice(-2) + "." + ("0" + (d.getMonth() + 1)).slice(-2) + "." +
                        d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
                } else {
                    // for grouped rows
                    var ret = '';
                    var vals = [];
                    params.node.allLeafChildren.map((e) => {
                        if (e.data[c.field] == null || e.data[c.field] == '' || vals.includes(e.data[c.field])) return true;
                        var d = new Date(e.data[c.field]);
                        ret += ("0" + d.getDate()).slice(-2) + "." + ("0" + (d.getMonth() + 1)).slice(-2) + "." +
                            d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ', ';
                        vals.push(e.data[c.field]);
                    });

                    return ret.slice(0, -2);
                }
            }


            obj.comparator = function (date1, date2) {

                function _monthToNum(date) {

                    if (date === undefined || date === null || date === '') {
                        return null;
                    }

                    var date_arr1 = date.split(' ');
                    var date_arr = date_arr1[0].split('.');

                    var yearNumber = parseInt(date_arr[2]);
                    var monthNumber = parseInt(date_arr[1]);
                    var dayNumber = parseInt(date_arr[0]);

                    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
                    // 29/08/2004 => 20040829
                    //console.log(result);
                    return result;
                }


                var date1Number = _monthToNum(date1);
                var date2Number = _monthToNum(date2);


                if (date1Number === null && date2Number === null) {
                    return 0;
                }
                if (date1Number === null) {
                    return -1;
                }
                if (date2Number === null) {
                    return 1;
                }

                return date1Number - date2Number;
            }

            obj.filterParams = {
                // provide comparator function
                comparator: (filterLocalDateAtMidnight, cellValue) => {
                    const dateAsString = cellValue;

                    if (dateAsString == null || dateAsString == '') {
                        return -1;
                    }

                    // In the example application, dates are stored as dd/mm/yyyy
                    // We create a Date object for comparison against the filter date
                    const parts = dateAsString.split(' ');
                    const dateParts = parts[0].split('.');
                    const day = Number(dateParts[0]);
                    const month = Number(dateParts[1]) - 1;
                    const year = Number(dateParts[2]);
                    const timeParts = parts[1].split(":");
                    const hour = timeParts[0];
                    const minute = timeParts[1];

                    const cellDate = new Date(year, month, day);

                    if (cellDate < filterLocalDateAtMidnight) {
                        return -1;
                    } else if (cellDate > filterLocalDateAtMidnight) {
                        return 1;
                    }
                    return 0;
                }
            }

        }

        if (table === 'employees') {
            if (c.field === 'surname') {
                //obj.checkboxSelection=true;
                obj.sort = 'asc';
                obj.pinned = 'left';
                obj.minWidth = 120;

                //obj.headerCheckboxSelection=true;
                obj.checkboxSelection = true;
                /*
                obj.cellRenderer = "ActionsRenderer";


                obj.cellRendererParams = {
                    gridType: "employees",
                    field:'surname',
                    showcommunication: showCommunication

                };
                 */
            }
            if (c.field === 'name') {
                //obj.pinned = 'left';
                // obj.minWidth = 160;
            }
        }
        columnDefs.push(obj);
    });


    return columnDefs;
}

export default Columns;