import { experimentalStyled } from '@material-ui/core/styles';

const LogoRoot = experimentalStyled('svg')``;

const Logo = (props) => (
  <LogoRoot
    version="1.1"
    {...props}
  >
    <title>AWA Partners</title>
    <defs>
      <filter id="svg_1_blur">
        <feGaussianBlur stdDeviation="0" in="SourceGraphic"/>
      </filter>
    </defs>
    <g>
      <text filter="url(#svg_1_blur)" fontWeight="bold" strokeWidth="0" xmlSpace="preserve" textAnchor="start" fontFamily="'Kumbh Sans'" fontSize="24" id="svg_1" y="24" x="0" stroke="#56aaff" fill="#03039e">BeloEstate</text>
    </g>
  </LogoRoot>
);

export default Logo;
