import UrlService from "../services/UrlService";


export default function apiPost(api, dataArray, handleResult, handleError, blob = false) {
    const at = localStorage.getItem("accessToken");

    const formData = new FormData();

    function isIterable(obj) {
        // checks for null and undefined
        if (obj == null) {
            return false;
        }
        return typeof obj[Symbol.iterator] === 'function';
    }


    for (const data of dataArray) {
        if (data.key.endsWith("[]")) {
            if (isIterable(data.value)) {
                for (const file of data.value) {
                    formData.append(data.key, file, file.name);
                }
            } else {
                formData.append(data.key, data.value, data.value.name);
            }
        } else {
            if(!data.value){
                formData.append(data.key, "");
            } else {
                formData.append(data.key, data.value);
            }
        }
    }

    return fetch(UrlService.apiDomain() + "api/" + api, {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + at,
        },
        body: formData
    })
        .then(r => {
            if (r.status !== 200 && r.status !== 2) {
                return {message: "Chyba serveru", success: false};
            }
            if (blob) return r.blob();
            return r.json()
        })
        .then(response => {
            if (!blob && !response.success) {
                return handleError(response.message, response);
            }
            return handleResult(response);
        })
}