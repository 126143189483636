import GridWrapper from "../../components/grid/gridWrapper";
import BasicAdd from "../../components/grid/basicAdd";
import apiGet from "../../api/apiGet";
import {Button, CircularProgress} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import LastUpdated from "./lastUpdated";
import apiPost from "../../api/apiPost";

export default function Payments({tab, presetValues, handleRowData}) {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);


    const refresh = () => {
        setLoading(true);
        apiGet('transactions').then((response) => {
            setLoading(false);
            if (response.success) {
                alert("Platby z banky byly úspěšně načteny");
                localStorage.setItem('public_settings_version', '0');
                localStorage.setItem('private_settings_version', '0');
                sessionStorage.clear();
                window.location.reload();
            } else {
                if (response.error_code === "7109" || response.error_code === "7101") {
                    alert('Spojení s bankou vypršelo. Prosím, přihlašte se znovu.');

                    let client_id = '8e0279eb-5317-4ec4-9b7c-d8c89866f48a';
                    let oauth_url = 'https://webapi.developers.erstegroup.com/api/csas/sandbox/v1/sandbox-idp/auth';
                    let redir_url = 'http://127.0.0.1:8000/api/csas_redirect';

                    if (process.env.NODE_ENV === "production") redir_url = 'https://api.beloestate.com/api/csas_redirect';
                    if (process.env.NODE_ENV === "production") oauth_url = 'https://bezpecnost.csas.cz/mep/fs/fl/oauth2/auth';
                    if (process.env.NODE_ENV === "production") client_id = 'bebf1fc5-dcd0-460c-8f0e-3201a92858d4';

                    let redir = oauth_url +
                        '?redirect_uri=' + redir_url +
                        '&client_id=' + client_id +
                        '&response_type=code&access_type=offline&state=csas-auth';

                    console.log(redir);
                    window.open(redir, '_blank').focus();
                    apiPost("log", [{key: "log", value: redir}],
                        async (result1) => {
                            console.log(result1);
                        },
                        (error, response) => {
                            console.log(error, response);
                        })

                } else {
                    alert("Unexpected error: " + response.error);
                }
            }

        });
    }

    return (
        <GridWrapper
            style={tab ? {mt: 2} : null}
            helmet="Dashboard: Payments List | Belo estate"
            title="Platby"
            model="payments"
            filter={handleRowData}>
            <BasicAdd model={"payments"} presetValues={presetValues}/>
            <Button disabled={loading}
                    type="button" variant={'contained'} sx={{ml: 1}}
                    onClick={refresh}>
                {!loading && t('Obnovit')}
                {loading && <CircularProgress fullWidth sx={{color: 'white', height: '100%'}} size={17}/>}
            </Button>
            <LastUpdated/>
        </GridWrapper>
    )
}