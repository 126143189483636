import {useState} from "react";
import {Button, Grid} from "@material-ui/core";
import FileUpload from "./FileUpload";
import {Check} from "@material-ui/icons";
import apiPost from "../../../api/apiPost";

export default function FileUploadWithSubmit({label, model, field, id, onAdd}) {
    const [file, setFile] = useState(undefined);

    const handleSubmit = () => {
        if (file) {
            apiPost('attachments/add',
                [{
                    key: 'file[]',
                    value: file
                }, {
                    key: 'model',
                    value: model
                }, {
                    key: 'field',
                    value: field
                }, {
                    key: 'id',
                    value: id
                }],(result) => {
                    setFile(undefined);
                    if(onAdd) onAdd(result.attachments);
                    alert('Soubor úspěšně nahrán');
                }, (error, response) => {
                    alert('ERROR: ' + error);
                    console.error(response);
                });
        }
    };

    console.log(file);

    return (
        <Grid item sx={{display: 'flex', m: '0'}}>
            <FileUpload file={file} multiple name={field} label={label} onChange={(name, f) => {
                if (file !== f) {
                    setFile(f);
                }
            }}/>
            {file !== undefined &&
                <Button
                    fullWidth
                    sx={{ml: 1}}
                    color="primary"
                    type="button"
                    variant="outlined"
                    onClick={handleSubmit}
                ><Check/> Odeslat
                </Button>
            }
        </Grid>
    )
}