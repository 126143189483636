import {Autocomplete, LinearProgress, TextField} from "@material-ui/core";
import {memo, useEffect, useState} from "react";
import useFilter from "../../../hooks/useFilter";

export default memo(({
                         keySelector,
                         valueSelector,
                         onChange,
                         label,
                         data_array,
                         data_array_model,
                         name,
                         required,
                         disabled,
                         inputSx,
                         freeSolo,
                         value,
                         company_id,
                         allowedRegex
                     }) => {
    const filter = useFilter();
    const [options, setOptions] = useState(null);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        let filtered = filter.byAccessAndFilter(data_array, data_array_model);

        if (company_id) {
            if (data_array_model === 'account_numbers') {
                filtered = filtered.filter(item => item.company_id === company_id);
            }
            if (data_array_model === 'additional_texts') {
                filtered = filtered.filter(item => item.company_ids.split('//').map(i => {
                    return i.toString();
                }).includes(company_id.toString()));
            }
            if (data_array_model === "projects"){
                filtered = filtered.filter(item => (item.company_ids ?? "") === "" || item.company_ids.split('//').map(i => {
                    return i.toString();
                }).includes(company_id.toString()));
            }
        }
        if(!freeSolo) {
            if (filtered.length === 1) {
                let val = filtered[0]?.id;
                onChange(name, val, !required || val);
            } else if (data_array_model === 'account_numbers') {
                onChange(name, null, !required);
            }
        }
        setOptions(filtered.map(item => {
            return {key: item.id, value: valueSelector(item), label: keySelector(item)}
        }));
    }, [data_array, company_id]);

    useEffect(() => {
        if(freeSolo){
            setSelected(value);
            return;
        }
        let newValue;
        if (!value) {
            newValue = null;
        } else {
            newValue = options?.find(item => item.value.toString() === value.toString());
            if (!newValue) {
                newValue = options?.find(item => item.toString() === value.toString());
            }
            if (!newValue && freeSolo) {
                newValue = value;
            }
        }
        if (selected !== newValue) {
            setSelected(newValue);
        }
    }, [value, options]);

    if (!data_array || !options) return <LinearProgress/>;

    return (
        <Autocomplete name={name} disabled={disabled} options={options}
                      onChange={(event, newValue) => {
                          let val = newValue?.value;
                          if (typeof newValue === 'string') {
                              val = newValue;
                          }

                          if (val !== value) {
                              onChange(name, val, !required || !!val);
                          }
                      }}
                      onInputChange={(event, newValue) => {
                          if (freeSolo && allowedRegex) {
                              if (!allowedRegex.test(newValue)) return;
                              onChange(name, newValue, !required || !!newValue);
                          }
                      }}
                      fullWidth
                      freeSolo={freeSolo}
                      autoSelect={freeSolo}
                      value={selected ?? (freeSolo ? '' : null)}
                      sx={inputSx}
                      renderInput={(params) =>
                          <TextField {...params} label={required ? label + '*' : label}
                                     inputProps={{...params.inputProps}}
                                     error={required && !selected}/>}/>
    )
}, (prev, next) => {
    return prev.disabled === next.disabled
        && prev.value === next.value
        && prev.selected === next.selected
        && prev.data_array === next.data_array
        && prev.company_id === next.company_id;
});