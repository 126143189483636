import {TextField} from "@material-ui/core";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import MobileDatePicker from "@material-ui/lab/MobileDatePicker";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import React, {memo, useEffect, useState} from "react";
import ruLocale from "date-fns/locale/ru";
import csLocale from "date-fns/locale/cs";
import {useTranslation} from "react-i18next";
import {format} from "date-fns";

const localeMap = {
    ru: ruLocale,
    cs: csLocale,
};

export default memo((props) => {
    const defaultValue = props.required ? "" : null;

    const {t} = useTranslation();
    const [locale] = useState(t('cs'));
    const [value, setValue] = useState(props?.value ? new Date(props.value) : null);

    useEffect(() => {
        let formatted = value ? format(value, 'yyyy-MM-dd') : defaultValue;
        if(formatted !== props.value) {
            props.onChange(props.name, formatted, !props.required || formatted)
        }
    }, [value])

    const handleChange = (newValue) => {
        setValue(newValue);
    }

    useEffect(() => {
        let val = props.value ? new Date(props.value) : null;
        if(val !== value) {
            setValue(val);
        }
    }, [props.value])

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
            <MobileDatePicker
                id={`datepicker-${props.name}`}
                label={props.required ? props.label + '*' : props.label} name={props.name}
                disabled={props.disabled}
                fullWidth
                mask="__.__.____"
                openTo="year"
                value={value}
                clearable
                onChange={handleChange}
                renderInput={(inputProps) => (
                    <TextField
                        fullWidth
                        variant={"outlined"}
                        {...inputProps}
                    />
                )}
            />
        </LocalizationProvider>
    )
}, (prev, next) => {
    return prev.value === next.value && prev.disabled === next.disabled;
});