import GridWrapper from "../../components/grid/gridWrapper";
import BasicAdd from "../../components/grid/basicAdd";
import TabsWrapper from "../../components/tabs/tabsWrapper";
import ModalWrapper from "../../components/modal/ModalWrapper";
import {useEffect, useState} from "react";
import GenerateDocuments from "../settings/documents/documents/generateDocuments";
import RentArchived from "./rentArchived";
import Details from "../../components/grid/details";
import {getName} from "../../services/getColumnDefinitions";
import Cohabitants from "../clients/cohabitants";
import Payments from "../payments";
import Cars from "../clients/cars";
import History from "../../components/history";
import AddRent from "./addRent/addRent";
import MassDocumentGeneration from "../../components/massDocumentGeneration/massDocumentGeneration";

export function getPriceFromFacility(newData, name, newValue) {
    if (name === "facility_id") {
        let facilities = JSON.parse(localStorage.getItem('facilities'));
        if (facilities) {
            let facility = facilities.find(f => f.id === newValue);
            if (!facility) {
                newData["rent_price"] = {value: "", valid: false};
                newData["utilities_price"] = {value: "", valid: false};
            } else {
                if (facility?.rent_price) {
                    newData["rent_price"] = {value: facility.rent_price, valid: true};
                }
                if (facility?.utilities_price) {
                    newData["utilities_price"] = {value: facility.utilities_price, valid: true};
                }
            }
        }
    }
    return newData;
}

export default function Rent({tab, presetValues, handleRowData}) {
    const [isOpen, setIsOpen] = useState(false);
    const [row, setRow] = useState({});
    const [tenant, setTenant] = useState();

    useEffect(() => {
        setTenant(JSON.parse(localStorage.getItem('clients')).find(t => t.id === row.tenant_id));
    }, [row]);

    return (
      <>
        <GridWrapper
          needTemplate={true}
          style={tab ? { mt: 2 } : null}
          defaultSort={"facility_id"}
          defaultSortOrder={"asc"}
          helmet="Dashboard: Rent List | Belo estate"
          title="Nájmy"
          model="rents"
          filter={handleRowData}
          pinned={"tenant_id"}
          clickable={[
            {
              field: "tenant_id",
              onClick: (r) => {
                setRow(r);
                setIsOpen(true);
              },
            },
          ]}
        >
          <MassDocumentGeneration modelName={"rents"} />
          <RentArchived />
          <AddRent presetValues={presetValues} />
        </GridWrapper>
        <ModalWrapper
          size="xl"
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <TabsWrapper
            tabs={[
              {
                label: "Nájemce",
                content: (
                  <Details
                    title={getName(tenant)}
                    item={tenant}
                    model={"clients"}
                  />
                ),
              },
              {
                label: "Spolubydlicí",
                content: (
                  <Cohabitants
                    tab
                    presetValues={[{ key: "rent_id", value: row?.id }]}
                    handleRowData={(data) => {
                      return (
                        data?.filter((item) => item.rent_id === row?.id) ?? []
                      );
                    }}
                  />
                ),
              },
              {
                label: "Vozidla",
                content: (
                  <Cars
                    tab
                    presetValues={[{ key: "rent_id", value: row?.id }]}
                    handleRowData={(data) => {
                      return (
                        data?.filter((item) => item.rent_id === row?.id) ?? []
                      );
                    }}
                  />
                ),
              },
              {
                label: "Platby",
                content: (
                  <Payments
                    tab
                    presetValues={[{ key: "rent_id", value: row?.id }]}
                    handleRowData={(data) => {
                      return (
                        data?.filter((item) => item.rent_id === row?.id) ?? []
                      );
                    }}
                  />
                ),
              },
              {
                label: "Generace dokumentů",
                content: (
                  <GenerateDocuments model={"rents"} id={row.id} item={row} />
                ),
              },
              {
                label: "Historie",
                content: <History model={"rents"} id={row.id} item={row} />,
              },
            ]}
          />
        </ModalWrapper>
      </>
    );
}