import {createContext, useState} from "react";

export const SearchContext = createContext(null);

export const SearchProvider = (props) => {
    const { children } = props;
    const [employee, setEmployee] = useState(null);

    const setFoundEmployee = (e) => {
        setEmployee(e);
    };

    const clearFoundEmployee = () => {
        setEmployee(null);
    };

    const getFoundEmployee = () => {
        return employee;
    };

    return (
        <SearchContext.Provider
            value={{
                employee: employee,
                setFoundEmployee: setFoundEmployee,
                clearFoundEmployee: clearFoundEmployee,
                getFoundEmployee: getFoundEmployee
            }}
        >
            {children}
        </SearchContext.Provider>
    );
};



