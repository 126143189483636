import React, {useState, useRef, forwardRef, useImperativeHandle, useEffect} from "react";

import {useTranslation} from 'react-i18next';
import $ from 'jquery';
import {
    Autocomplete, TextField
} from '@material-ui/core';
import useFilter from "../hooks/useFilter";
import {getName} from "../services/getColumnDefinitions";

export default forwardRef((props, ref) => {
    const {t} = useTranslation();
    const filter = useFilter();
    const [value, setValue] = useState('');
    const [optionsArray, setOptionsArray] = useState([]);

    const inputRef = useRef(null);
    console.log(props);
    let locale = 'cs';
    if (localStorage.getItem('locale') !== null) locale = localStorage.getItem('locale');

    async function getOptions() {
        let data_array = props.data_array ?? [];
        if (props.colDef.field === 'down_payment_invoice_id') {
            data_array = props.data_array.filter(i => i.invoice_type?.toString() === '2');
        }
        if (props.colDef.field === 'danovy_doklad_invoice') {
            data_array = props.data_array.filter(i => i.invoice_type?.toString() === '3');
        }

        if(props.data_array_model === 'projects'){
			
			console.log( "projects", props, props.data_array_model, props.data.company_ids, data_array );
			
			let company_ids = [];
			
			if( props.model === "suppliers" ){
			
				company_ids = ( props.data.company_ids === null ) ? [ ] : props.data.company_ids.split('//');
			
			} else if( props.model === "received_invoices" ){
				
				company_ids = ( props.data.company_id === null ) ? [ ] : [ props.data.company_id ];
				
			}
			
            data_array = data_array.filter(i => {
				
                if(!i.company_ids) {
					
					return true;
				
				}
				
                let ids = i.company_ids.split('//');
				
				for(
					let it in company_ids
				){
					
					if( ids.includes( company_ids[ it ].toString() ) ){
						
						return true;
						
					}
					
				}
				
                return false;
				
            });
			
			console.log( data_array );
        }

        if (props.data_array_model === "invoice_item_names") {
            setOptionsArray(props.data_array.map(data => {
                return {
                    value: data.name,
                    label: data.name
                }
            }));
        } else if (props.data_array_model === 'additional_texts') {
            setOptionsArray(props.data_array.map(data => {
                return {
                    value: data.text,
                    label: data.text
                }
            }));
        } else {
            setOptionsArray(filter.byAccessAndFilter(data_array, props.data_array_model).map(data => {
                return {
                    value: data.id,
                    label: getName(data, props.data_array_model)
                }
            }));
        }
    }

    useEffect(() => {
        getOptions();
    }, [filter]);

    function getVal(e) {
        let val = props.data[props.column.colId];
        if (val == null) val = '0';
        setValue(val);
    }

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            },
            afterGuiAttached: async () => {

                setValue(props.value);
                await getOptions();
                getVal();


                inputRef.current.focus();
                inputRef.current.click();
                $('#mycstselect svg').click();
            },

            isPopup: () => {
                return true;
            }
        };
    });

    let val;
    if (props.data_array_model !== "invoice_item_names" && props.data_array_model !== "additional_texts") {
        val = optionsArray.find((option) => option?.value?.toString() === value?.toString()) ?? "";
    } else {
        val = value?.toString() ?? "";
    }

    return (
        <Autocomplete
            ref={inputRef}
            options={optionsArray}
            fullWidth
            sx={{minWidth: '200px', maxWidth: '200px'}}
            value={val}
            onChange={(_, pair) => {
                if(!pair) {
                    let confirmed = window.confirm(t('Clear value?'));
                    if(!confirmed) return;
                }
                if (typeof pair !== 'object') {
                    setValue(pair?.toString() ?? "");
                } else {
                    setValue(pair?.value);
                }
            }}
            freeSolo={props.data_array_model === "invoice_item_names"
                || props.data_array_model === 'additional_texts'}
            autoSelect={props.data_array_model === "invoice_item_names"
                || props.data_array_model === 'additional_texts'}
            renderInput={(params) =>
                <TextField {...params}
                           label={props.colDef.headerName}/>}/>
    )
})
