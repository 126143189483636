import apiPost from "../../api/apiPost";
import {openBlob} from "../../utils/blobUtils";
import {Autocomplete, Button, LinearProgress, TextField, Checkbox, FormControlLabel} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {getLocalJson} from "../../utils/localStorageUtills";
import ModalWrapper from "../modal/ModalWrapper";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import MobileDatePicker from "@material-ui/lab/MobileDatePicker";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import csLocale from "date-fns/locale/cs";
import {useTranslation} from "react-i18next";

const localeMap = {
    ru: ruLocale,
    cs: csLocale,
};
export default function MassDocumentGeneration({modelName, selected, isValid}) {
    const {t} = useTranslation();
    const [locale] = useState(t('cs'));

    const [document, setDocument] = useState(null);
    const [documents, setDocuments] = useState(null);

    const [docx, setDocx] = useState(false);


    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [generationDate, setGenerationDate] = useState(null);

    const [date, setDate] = useState('now');
    const [dates, setDates] = useState([]);

    useEffect(() => {
        let mounted = true;
        apiPost(`${modelName}/history/dates`,
            [{
                key: 'items', value: JSON.stringify(selected.map(i => i.id))
            }],
            (response) => {
                if (mounted) {
                    setDates(Object.values(response.data).sort());
                }
            },
            () => {
                setDocument(null);
                setLoading(false);
                setIsOpen(false);
                alert("Chyba serveru");
            });
        return function cleanup() {
            mounted = false
        };
    }, [isOpen]);

    useEffect(() => {
        setDocuments(getLocalJson('document_templates')
            .filter(template => template.model === modelName)
            .map(template => ({value: template.id, label: template.name})));
    }, [modelName]);

    const handleSubmit = () => {

        dayjs.extend(customParseFormat);
        let stringGenerationDate = dayjs(generationDate?.toISOString() ?? new Date()).format('YYYY-MM-DD');

        setLoading(true);
        apiPost(`${modelName}/documents/mass_generate/${date}/${stringGenerationDate}/${docx}`,
            [{
                key: 'items', value: JSON.stringify(selected.map(i => (
                    {
                        model_id: i.id,
                        document_id: document.value
                    }
                )))
            }],
            (blob) => {
                setDocument(null);
                setLoading(false);
                setIsOpen(false);
                setDocx(false);
                openBlob(blob);
            },
            () => {
                setDocument(null);
                setLoading(false);
                setIsOpen(false);
                setDocx(false);
                alert("Chyba serveru");
            }, true);
    }

    let count = selected?.length ?? 0;
    let text = `Hromadný tisk (Vybráno: ${count})`;
    let valid = count > 0;

    if (isValid) {
        let result = !isValid(selected);
        if (!result.valid) {
            text = result.text;
            valid = false;
        }
    }

    return <>
        <Button sx={{px: 2, mx: 2}} disabled={!valid} variant={'contained'}
                onClick={() => setIsOpen(true)}>{text}</Button>
        <ModalWrapper isOpen={isOpen} onClose={() => setIsOpen(false)}>
            {!loading && <>
                <Autocomplete
                    fullWidth
                    options={documents ?? []}
                    onChange={(event, newValue) => {
                        setDocument(newValue);
                    }}
                    value={document}
                    renderInput={(params) =>
                        <TextField {...params} label={'Document'}/>}/>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
                    <MobileDatePicker
                        value={generationDate}
                        label={'Datum generace'}
                        fullWidth
                        mask="__.__.____"
                        openTo="year"
                        clearable
                        onChange={(val) => {
                            setGenerationDate(val);
                        }}
                        renderInput={(params) => {
                            if (generationDate == null) {
                                params.value = 'teď';
                                params.inputProps.value = 'teď';
                            }
                            return <TextField
                                sx={{mr: 2, mt: 1}}
                                fullWidth
                                variant={"outlined"}
                                {...params}
                            />
                        }}
                    />
                </LocalizationProvider>
                <Autocomplete
                    fullWidth
                    sx={{mt: 1}}
                    options={dates}
                    value={date}
                    onChange={(event, value) => {
                        setDate(value ?? 'now');
                    }}
                    renderInput={(params) =>
                        <TextField
                                   {...params} label={'Datum historie'}
                                   inputProps={{...params.inputProps, maxLength: 30}}/>}/>
            <FormControlLabel sx={{width: '150px'}}
                              control={<Checkbox value={docx} onChange={(e) => setDocx(e.target.checked ? 1 : 0)}/>}
                              label={'Word'}/>
                {document &&
                    <Button sx={{mt: 2}} variant={'contained'} fullWidth onClick={handleSubmit}>Submit</Button>}
            </>}
            {loading && <LinearProgress fullWidth/>}
        </ModalWrapper>
    </>
}