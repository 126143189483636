import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { authApi } from '../__fakeApi__/authApi';
import axios from "axios";
import UrlService from "../services/UrlService";
import SessionStorageService from "../services/SessionStorageService";
import { useTranslation } from 'react-i18next';
const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user,lists_data } = action.payload;

      if (lists_data) SessionStorageService.initializeLocalDB(lists_data);

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user, lists_data } = action.payload;
      if (lists_data) SessionStorageService.initializeLocalDB(lists_data);
    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider = (props) => {
    const { t } = useTranslation();
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {
         // const user = await authApi.me(accessToken);
            const options = {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            };

            var public_settings_version=1;
            var private_settings_version=1;

            if (localStorage.getItem('private_settings_version')!=null) private_settings_version=localStorage.getItem('private_settings_version');
            if (localStorage.getItem('public_settings_version')!=null) public_settings_version=localStorage.getItem('public_settings_version');

          var user = await axios.get(UrlService.apiDomain()+'api/user/null/'+private_settings_version,options);
const lists_data=user.data;
user=user.data.user;
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user:user,
                lists_data:lists_data
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
                lists_data:null
            }
          });
        }
      } catch (err) {

        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
              lists_data:null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {

      const response = await axios.post(UrlService.loginUrl(), {email:email, password:password});

      if (!response.data.token) {
        alert(t('Chybné přihašovací údaje'));
          return new Error(t('Chybné přihašovací údaje'));
      }

   // const accessToken = await authApi.login({ email, password });
  //  const user = await authApi.me(accessToken);


      const accessToken = response.data.token;
      const user = response.data.user;

    localStorage.setItem('accessToken', accessToken);
      const options = {
          headers: {
              Authorization: "Bearer " + accessToken,
          },
      };

      var public_settings_version=1;
      var private_settings_version=1;

      if (localStorage.getItem('private_settings_version')!=null) private_settings_version=localStorage.getItem('private_settings_version');
      if (localStorage.getItem('public_settings_version')!=null) public_settings_version=localStorage.getItem('public_settings_version');

      const user_full = await axios.get(UrlService.apiDomain()+'api/user/null/'+private_settings_version,options);
      const lists_data=user_full.data;
    dispatch({
      type: 'LOGIN',
      payload: {
        user:user,
          user_full:user_full,
          lists_data:lists_data
      }
    });
  };

  const logout = async () => {
    localStorage.removeItem('accessToken');
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email, name, password) => {
    const accessToken = await authApi.register({ email, name, password });
    const user = await authApi.me(accessToken);

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
