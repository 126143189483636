import {Button, Chip, Container, Divider, Grid, LinearProgress} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {memo, useEffect, useState} from "react";
import apiPost from "../../../api/apiPost";
import getColumnDefinitions from "../../../services/getColumnDefinitions";
import FormWithValidation from "../../../components/form/FormWithValidation";
import BasicAdd from "../../../components/grid/basicAdd";
import {getPriceFromFacility} from "../index";
import {addToDataArray} from "../../../utils/localStorageUtills";

function getVariableSymbol(newData, name, newValue) {
    if (name === "facility_id") {
        let facilities = JSON.parse(localStorage.getItem('facilities'));
        if (facilities) {
            let facility = facilities.find(f => f.id === newValue);
            let facilityName = facility?.name?.replace(/\D/g, '').padStart(3, '0');
            if (!facilityName) {
                newData["variable_symbol"] = {value: "", valid: false};
            } else {
                newData["variable_symbol"] = {value: `{rentId}${facilityName}`, valid: true};
            }
        }
    }
    return newData;
}

export default memo((props) => {

    const {t} = useTranslation();
    const [rentDefs, setRentDefs] = useState(undefined);

    const [rentData, setRentData] = useState([]);
    const [cohabitants, setCohabitants] = useState([]);
    const [cars, setCars] = useState([]);

    const [rentValid, setRentValid] = useState(false);

    const [deleteDraft, setDeleteDraft] = useState(null);

    const handleSubmit = () => {
        if (deleteDraft) {
            deleteDraft();
        }
        apiPost("rents/add", props.presetValues ? rentData.concat(props.presetValues) : rentData,
            async (result1) => {
                console.log("Rent added successfully", result1);
                props.onAdd(result1.data);
                for (const cohabitant of cohabitants) {
                    await apiPost("cohabitants/add", cohabitant.concat({key: 'rent_id', value: result1.data.id}),
                        (added) => {
                            addToDataArray("cohabitants", added);
                            alert(`Spolubydlicí ${cohabitant[0].value + cohabitant[1].value} přidán úspěšně`);
                            return true;
                        },
                        (error, response) => {
                            alert(`Spolubydlicí ${cohabitant[0].value + cohabitant[1].value} nebyl přidán. Chyba.`);
                            console.log(error, response);
                            return false;
                        })
                }
                for (const car of cars) {
                    await apiPost("cars/add", car.concat({key: 'rent_id', value: result1.data.id}),
                        (added) => {
                            addToDataArray("cars", added);
                            alert(`Car ${car[0].value} přidán úspěšně`);
                            return true;
                        },
                        (error, response) => {
                            alert(`Car ${car[0].value} nebyl přidán. Chyba.`);
                            console.log(error, response);
                            return false;
                        })
                }
                alert(t("Spolubydlicí přidán úspěšně"));
                localStorage.setItem('public_settings_version', '0');
                localStorage.setItem('private_settings_version', '0');
                sessionStorage.clear();
            },
            (error, response) => {
                alert(error);
                console.log(error, response);
            })
    }

    useEffect(() => {
        let mounted = true

        getColumnDefinitions(t, 'rents', true, (definitions) => definitions.filter(def => !props.presetValues?.find(val => def.name === val.key))).then((definitions) => {
            if (mounted) {
                for (const key of Object.keys(definitions)) {
                    rentData[key] = [];
                }
                setRentDefs(definitions)
            }
        });
        return function cleanup() {
            mounted = false
        }
    }, [t])

    if (!rentDefs) {
        return <LinearProgress fullWidth sx={{minWidth: "200px"}}/>
    }

    let rentForm = <>
        <div style={{marginTop: '8px'}}>{t("Rent")}</div>
        <Divider sx={{my: 2}}/>
        <FormWithValidation
            data={rentData['default']}
            form={rentDefs['default']}
            getFunctionToDeleteDraft={(func) => {
                setDeleteDraft(() => func);
            }}
            handleChange={(newData, name, newValue) => {
                newData = getPriceFromFacility(newData, name, newValue);
                return getVariableSymbol(newData, name, newValue);
            }}
            onChange={(d, v) => {
                setRentData(d);
                setRentValid(v);
            }}
        /></>

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {rentForm}
            </Grid>
            <Grid item xs={12}>
                <Divider sx={{my: 2}}/>
                <Container sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                    {cohabitants.map((cohabitant, i) => {
                        return (
                            <Chip
                                sx={{my: 1, mr: 2, fontSize: '1rem'}}
                                label={cohabitant[0].value + " " + cohabitant[1].value + " " + cohabitant[2].value}
                                variant="outlined" onDelete={() => {
                                let copy = [...cohabitants];
                                copy.splice(i, 1);
                                setCohabitants(copy);
                            }}/>
                        )
                    })}
                    <div style={{width: '150px', marginTop: '6px'}}>
                        <BasicAdd title={"Přidat spolubydlicí"} model="cohabitants"
                                  presetValues={[{key: 'rent_id', value: 0}]}
                                  interceptAdd={(data) => {
                                      console.log(data);
                                      setCohabitants((prevState => {
                                          return [...prevState, data]
                                      }));
                                  }}/>
                    </div>
                </Container>
            </Grid>
            <Grid item xs={12}>
                <Divider sx={{my: 2}}/>
                <Container sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                    {cars.map((car, i) => {
                        return (
                            <Chip
                                sx={{my: 1, mr: 2, fontSize: '1rem'}}
                                label={car[0].value}
                                variant="outlined" onDelete={() => {
                                let copy = [...cars];
                                copy.splice(i, 1);
                                setCars(copy);
                            }}/>
                        )
                    })}
                    <div style={{width: '150px', marginTop: '6px'}}>
                        <BasicAdd title={"Přidat vozidlo"} model="cars"
                                  presetValues={[{key: 'rent_id', value: 0}]}
                                  interceptAdd={(data) => {
                                      console.log(data);
                                      setCars((prevState => {
                                          return [...prevState, data]
                                      }));
                                  }}/>
                    </div>
                </Container>
            </Grid>
            <Grid item xs={12} sx={{p: 0}}>
                <Divider sx={{mb: 2}}/>
                <Button fullWidth
                        onClick={() => {
                            if (rentData.find(d => d.key === 'facility_id')?.value?.toString() !== '94') {
                                let facilityId = rentData.find((d) => d.key === 'facility_id').value;
                                let rents = JSON.parse(localStorage.getItem('rents')).filter(rent => !rent.ukonceni_najmu && rent.facility_id === facilityId);

                                if (rents) {
                                    let datesToCompare = {
                                        from: new Date(rentData.find(d => d.key === 'from_date').value),
                                        to: new Date(rentData.find(d => d.key === 'to_date').value)
                                    };

                                    for (const rent of rents) {
                                        let rentDuration = {to: new Date(rent.to_date), from: new Date(rent.from_date)}
                                        if (datesToCompare.from < rentDuration.to && datesToCompare.to > rentDuration.from) {
                                            console.log("Overlaps with", rent);
                                            alert(`Rents duration overlaps with ${rent.from_date}/${rent.to_date}`)
                                            return;
                                        }
                                    }
                                }
                            }

                            handleSubmit();
                            props.onClose();
                        }}
                        variant="contained" color="primary"
                        disabled={!rentValid}>
                    {t("Odeslat")}
                </Button>
            </Grid>
        </Grid>
    );
});