import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/core";
import {Archive, Check, Visibility} from "@material-ui/icons";

    export default function RentArchived({setFilter, isTenant}) {
        const {t} = useTranslation();
        const [show, setShow] = useState('active');

        const handleChange = (event, newAlignment) => {
            if(newAlignment) {
                setShow(newAlignment);
            }
        };

        useEffect(() => {
            let filter;
            switch (show) {
                case 'active':
                    if(isTenant){
                        filter = (tenant) => !tenant.archived;
                    } else {
                        filter = (item) => !item.ukonceni_najmu;
                    }
                    break;
                case 'archived':
                    if(isTenant){
                        filter = (tenant) => tenant.archived;
                    } else {
                        filter = (item) => item.ukonceni_najmu;
                    }
                    break;
                default:
                    filter = () => true;
            }
            setFilter(() => filter);
        }, [show]);

        return (
            <ToggleButtonGroup
                value={show}
                exclusive
                onChange={handleChange}
                color="primary"
                aria-label={t("Show")}
                sx={{mr: 2}}
            >
                <ToggleButton sx={{py:0}} value="active" aria-label="right aligned">
                    <Check sx={{mr: 1}}/>{t('Aktivní')}
                </ToggleButton>
                <ToggleButton sx={{py:0}} value="all" aria-label="centered">
                    <Visibility sx={{mr: 1}}/>{t('Vše')}
                </ToggleButton>
                <ToggleButton sx={{py:0}} value="archived" aria-label="left aligned">
                    <Archive sx={{mr: 1}}/>{t('Archiv')}
                </ToggleButton>
            </ToggleButtonGroup>
        );
    }