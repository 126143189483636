import {Autocomplete, TextField} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import useFilter from "../../hooks/useFilter";
import {strComparator} from "../../services/getColumnDefinitions";

export default function FilterSelect() {
    const filter = useFilter();
    const [companies, setCompanies] = useState([]);

    const [buildings, setBuildings] = useState([]);

    const [building, setBuilding] = useState();
    const [company, setCompany] = useState();

    useEffect(() => {
        let cFilter = localStorage.getItem("companyFilter");
        if (cFilter) {
            setCompany(JSON.parse(cFilter));
        }
        let bFilter = localStorage.getItem("buildingFilter");
        if (bFilter) {
            setBuilding(JSON.parse(bFilter));
        }
    }, []);

    useEffect(() => {
        if (company) {
            localStorage.setItem('companyFilter', JSON.stringify(company));
        } else {
            localStorage.removeItem('companyFilter');
        }
        if (building) {
            localStorage.setItem('buildingFilter', JSON.stringify(building));
        } else {
            localStorage.removeItem('buildingFilter');
        }
    }, [building, company]);

    useEffect(() => {
        filter.setCompanyFilter(company)
        let pronajimatel = JSON.parse(localStorage.getItem('companies')).find(c => c.id === company)?.pronajimatel ?? 1;
        filter.setPronajimatel(pronajimatel);
        setBuilding(null);
    }, [company])

    useEffect(() => {
        filter.setBuildingFilter(building)
    }, [building])

    useEffect(() => {
        if(filter.clearRequested){
            setCompany(null);
            setBuilding(null);
            filter.cleared();
        }
    },[filter])

    useEffect(() => {
        setCompanies(filter.byAccess(JSON.parse(localStorage.getItem('companies')), 'companies')
            .map(c => {
                return {
                    value: c.id, label: c.name
                }
            }).sort((a,b) => strComparator(a.label, b.label)))
        setBuildings(filter.byAccessAndFilter(JSON.parse(localStorage.getItem('buildings')), 'buildings')
            .map(b => {
                return {
                    value: b.id, label: b.name
                }
            }).sort((a,b) => strComparator(a.label, b.label)))
    }, [filter])


    return (<>
            <Autocomplete
                sx={{minWidth: '200px'}}
                size={"small"}
                value={companies.find(i => i.value === company) ?? null}
                options={companies}
                onChange={(_, pair) => {
                    setCompany(pair?.value);
                }}
                renderInput={(params) => <TextField {...params} label={'Firma'}/>}/>
            {filter.pronajimatel === 1 && <Autocomplete
                sx={{minWidth: '200px', ml: '10px'}}
                size={"small"}
                value={buildings.find(i => i.value === building) ?? null}
                options={buildings}
                onChange={(_, pair) => {
                    setBuilding(pair?.value);
                }}
                renderInput={(params) => <TextField {...params} label={'Budova'}/>}/>}
            <Autocomplete
                sx={{width: '120px', ml: '10px'}}
                size={"small"}
                options={['2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030']}
                onChange={(_, val) => {
                    filter.setYearFilter(val);
                }}
                renderInput={(params) => <TextField {...params} label={'Rok'}/>}/>
            <Autocomplete
                sx={{minWidth: '120px', ml: '10px'}}
                size={"small"}
                options={['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']}
                onChange={(_, val) => {
                    filter.setMonthFilter(val);
                }}
                renderInput={(params) => <TextField {...params} label={'Měsíc'}/>}/>
        </>

    )
}