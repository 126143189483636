import axios from "axios";


class HttpService {
    async get(url) {
        const at = localStorage.getItem("accessToken");
        const options = {
            headers: {
                Authorization: "Bearer " + at,
            },
        };
        try {
            return await axios.get(url, options);
        } catch (error) {
            console.error("Not able to fetch data", error);
        }
    }

    async getblob(url) {
        const at = localStorage.getItem("accessToken");
        const options = {
            responseType: 'blob',
            headers: {
                Authorization: "Bearer " + at,
            },
        };
        try {
            return await axios.get(url, options);
        } catch (error) {
            console.error("Not able to fetch data", error);
        }
    }

    async delete(url) {
        const at = localStorage.getItem("accessToken");
        const options = {
            headers: {
                Authorization: "Bearer " + at,
            },
        };
        try {
            return await axios.delete(url, options);
        } catch (error) {
            console.error("Not able to fetch data", error);
        }
    }

    async postblob(url, data, options = null) {
        const at = localStorage.getItem("accessToken");
        const postOptions = {
            responseType: 'blob',
            headers: {
                Authorization: "Bearer " + at,
            },
        };
        const finalOptions = Object.assign(postOptions, options);
        try {
            return await axios.post(url, data, finalOptions);
        } catch (error) {
            console.error("Not able to fetch data", error);
            return error.response !== undefined ? error.response : error;
        }
    }

    async post(url, data, options = null) {
        const at = localStorage.getItem("accessToken");
        const postOptions = {
            headers: {
                Authorization: "Bearer " + at,
            },
        };
        const finalOptions = Object.assign(postOptions, options);
        try {
            return await axios.post(url, data, finalOptions);
        } catch (error) {
            console.error("Not able to fetch data", error);
            return error.response !== undefined ? error.response : error;
        }
    }

    async put(url, data, options = null) {
        const at = localStorage.getItem("accessToken");
        const postOptions = {
            headers: {
                Authorization: "Bearer " + at,
            },
        };
        const finalOptions = Object.assign(postOptions, options);
        try {
            return await axios.put(url, data, finalOptions);
        } catch (error) {
            console.error("Not able to fetch data", error);
            return error.response !== undefined ? error.response : error;
        }
    }

}

export default new HttpService();
