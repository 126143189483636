import GridWrapper from "../grid/gridWrapper";
import {LinearProgress} from "@material-ui/core";
import {useEffect, useState} from "react";
import apiGet from "../../api/apiGet";

export default function History({model, id}) {
    const [data, setData] = useState();
    const [columnDefinitions, setColumnDefinitions] = useState();

    useEffect(() => {
        let mounted = true;

        apiGet(`${model}/${id}/history`).then(response => {
            if (mounted) {
                setData(Object.values(response.data));
                setColumnDefinitions(response.columns);

                console.log(response);
            }
        })

        return function cleanup() {
            mounted = false;
        }
    }, [id, model])

    if (!columnDefinitions) {
        return <LinearProgress fullWidth sx={{mt: 2}}/>
    }

    return <GridWrapper
        data={data}
        colDefs={[...columnDefinitions,
            {
                headerName: 'Odstranit',
                cellRendererParams: {
                    handleDelete: (props) => {
                        apiGet(`${model}/${id}/history/${props.node.data.date_raw}/delete`).then((response) => {
                            if(response.success) {
                                setData(data.filter(item => item.date !== props.node.data.date));
                            }
                        });
                    },
                    isDisabled: (props) => {
                        return props.node.data.date === "now";
                    }
                },
                cellRenderer: 'DeleteRenderer',
                suppressMenu: true,
                editable: false,
                resizable: false,
                sortable: false,
                minWidth: 100,
                maxWidth: 100,
            }]}
        defaultSort={'date'}
    />
}
