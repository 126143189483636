import { useTranslation } from "react-i18next";
import LocaleCs from "../../aggrid/locale.cs";
import LocaleEn from "../../aggrid/locale.en";
import LocaleRu from "../../aggrid/locale.ru";
import useSettings from "../../hooks/useSettings";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, {
  useCallback,
  useEffect,
  useState,
  Children,
  cloneElement,
} from "react";
import HttpService from "../../services/HttpService";
import UrlService from "../../services/UrlService";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  Container,
  Grid,
  ThemeProvider,
  Typography,
  IconButton,
  Autocomplete,
  TextField,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "ag-grid-community";
import addPropsToChildren from "../../utils/addPropsToChildren";
import getColumnDefinitions from "../../services/getColumnDefinitions";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import apiGet from "../../api/apiGet";
import apiPost from "../../api/apiPost";
import MultiFileModal from "./multiFileModal";
import Select from "../../aggrid/Select";
import FileEditor from "../../aggrid/FileEditor";
import CheckboxRenderer from "../../aggrid/CheckboxEditor";
import { OpenInNew, Save } from "@material-ui/icons";
import DeleteRenderer from "../../aggrid/DeleteRenderer";
import DownloadAttachment from "../../aggrid/downloadAttachment";
import DatePicker from "../../aggrid/DatePicker";
import useFilter from "../../hooks/useFilter";
import MultiSelect from "../../aggrid/MultiSelect";
import { createTheme } from "@material-ui/core/styles";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import {
  addToDataArray,
  updateDataInArray,
} from "../../utils/localStorageUtills";
import DateModal from "../dateModal/dateModal";
import JsxRenderer from "../../aggrid/JsxRenderer";
import ExternalLink from "../../icons/ExternalLink";
import Notes from "./notes";
import permissions from "../../pages/settings/permissions";

LicenseManager.setLicenseKey(
  "CompanyName=GTS - Global Tungsten Solution s.r.o.,LicensedGroup=GTSDB,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-015194,ExpiryDate=23_May_2022_[v2]_MTY1MzI2MDQwMDAwMA==d202e751d68f01012278c38da399e166"
);

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

const defaultFrameworkComponents = {
  Select: Select,
  FileEditor: FileEditor,
  CheckboxRenderer: CheckboxRenderer,
  OpenInNewRenderer: () => {
    return <OpenInNew color={"primary"} />;
  },
  DeleteRenderer: DeleteRenderer,
  DownloadAttachment: DownloadAttachment,
  DatePicker: DatePicker,
  MultiSelect: MultiSelect,
  JsxRenderer: JsxRenderer,
};

export default function GridWrapper({
  children,
  model,
  data,
  colDefs,
  helmet,
  title,
  frameworkComponents,
  gridProps,
  pinned,
  clickable,
  style,
  handleRowDataOnInit,
  interceptChange,
  onUpdateResponse,
  hidden,
  defaultSort,
  defaultSortOrder,
  small,
  filter: handleRowData,
  getRowStyle,
  handleColumnDefs,
  onSelected,
  historyYear,
  historyMonth,
  needTemplate,
}) {
  const { t } = useTranslation();
  const filter = useFilter();
  let lt;
  if (t("cs") === "cs") lt = LocaleCs;
  else if (t("cs") === "en") lt = LocaleEn;
  else if (t("cs") === "ru") lt = LocaleRu;
  const { settings } = useSettings();
  const [rowData, setRowData] = useState(data ?? null);
  const [rowDataFilter, setRowDataFilter] = useState(() => (i) => {
    return true;
  });
  const [filteredData, setFilteredData] = useState(null);

  const [locText, setLocText] = useState(lt);

  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (onSelected) {
      onSelected(selectedRows);
    }
  }, [onSelected, selectedRows]);

  useEffect(() => {
    setLocText(lt);
  }, [lt]);

  const [ready, setReady] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);
  const [columnDefs, setColumnDefs] = useState(colDefs ?? []);

  const [multiFileData, setMultiFileData] = useState(null);

  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);

  const [noteOpen, setNoteOpen] = useState(false);
  const [noteId, setNoteId] = useState(null);

  useEffect(() => {
    let filterYear = filter.getYearFilter();
    let monthFilter = filter.getMonthFilter();

    if (!historyYear || !historyMonth) {
      if (filterYear !== year) {
        setYear(filterYear);
      }
      if (monthFilter !== month) {
        setMonth(monthFilter);
      }
    }
  }, [filter]);

  useEffect(() => {
    if (historyMonth && historyYear) {
      setYear(historyYear);
      setMonth(historyMonth);
    } else {
      setYear(null);
      setMonth(null);
    }
  }, [historyMonth, historyYear]);

  const defaultColDef = {
    flex: 1,
    minWidth: 130,
    editable: false,
    resizable: true,
    suppressMovableColumns: true,
    sortable: true,
    filter: "agTextColumnFilter",
    menuTabs: ["filterMenuTab", "columnsMenuTab"],
  };

  useEffect(() => {
    let permissions = JSON.parse(localStorage.getItem("my_permissions")) ?? [];
    let mounted = true;

    if (!colDefs) {
      if (columnDefs.length === 0) {
        getColumnDefinitions(t, model, false).then((d) => {
          if (mounted) {
            if (!d || d.length === 0) {
              alert("Žádné kolonky v modelu " + model);
              d = [];
            }
            if (pinned) {
              d.find((e) => e.field === pinned).pinned = "left";
            }
            if (hidden) {
              d = d.filter((def) => !hidden.includes(def.field));
            }
            if (permissions?.includes(`${model}.delete`)) {
              d.push({
                headerName: "Odstranit",
                cellRendererParams: {
                  model: model,
                },
                cellRenderer: "DeleteRenderer",
                suppressMenu: true,
                editable: false,
                resizable: false,
                sortable: false,
                minWidth: 100,
                maxWidth: 100,
              });
            }
            d.push({
              headerName: "#",
              valueGetter: "node.rowIndex + 1",
              lockPosition: "left",
              pinned: "left",
              maxWidth: 150,
              sortable: false,
              resizable: false,
              suppressMenu: true,
              checkboxSelection: true,
              headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
            });

            d.push({
              minWidth: 100,
              headerName: "Notes",
              field: "note",
              pinned: "left",
              cellRenderer: "JsxRenderer",
              cellRendererParams: {
                jsx: (props) => (
                  <>
                    <Button
                      sx={{ ml: "-10px", width: "64px" }}
                      color="primary"
                      id="fil_attach"
                      className="btn btn-default btn-sm delete-ingrid-but"
                      fullWidth
                      onClick={() => {
                        setNoteId(props.data.id);
                        setNoteOpen(true);
                      }}
                    >
                      <ExternalLink />
                    </Button>
                    {props.value}
                  </>
                ),
              },
            });
            for (const columnDef of d) {
              console.log(model, columnDef);

              if (
                model == "supplier_account_numbers" &&
                columnDef.field == "supplier_id"
              ) {
                columnDef.editable = false;
              } else if (
                model == "received_invoices" &&
                (columnDef.field == "supplier_id" ||
                  columnDef.field == "supplier_account_number_id")
              ) {
                columnDef.editable = false;
              }

              let sort = defaultSort ?? "updated_at";
              if (columnDef.field === sort) {
                columnDef.sort = defaultSortOrder ?? "desc";
              }
            }
            if (handleColumnDefs) {
              setColumnDefs(handleColumnDefs(d));
            } else {
              setColumnDefs(d);
            }
          }
        });
      }
    }

    if (data === undefined) {
      let date = "now/now";

      if (year && month) {
        date = `${year}/${month}`;
      }

      apiGet(`${model}/index/${date}`).then((response) => {
        if (mounted) {
          let data = response?.data ?? [];
          setRowData(handleRowDataOnInit ? handleRowDataOnInit(data) : data);
          setReady(true);
        }
      });
    }

    return function cleanup() {
      mounted = false;
    };
  }, [model, handleRowDataOnInit, year, month]);

  useEffect(() => {
    if (data) {
      setRowData(handleRowDataOnInit ? handleRowDataOnInit(data) : data);
      setReady(true);
    }
  }, [data]);

  useEffect(() => {
    if (colDefs) {
      setColumnDefs(colDefs);
      setReady(true);
    }
  }, [colDefs]);

  const show_ondemand = (id, field) => {
    HttpService.get(
      UrlService.apiDomain() +
        "api/" +
        model +
        "/show_on_demand/" +
        id +
        "/" +
        field
    )
      .then((res) => {
        let node = gridApi.getRowNode(id - 1);
        node.data[field] = res.data.value;
        node.setData(node.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onCellClicked = (e) => {
    let field = e.colDef.field;
    if (e.data[field] === "ZOBRAZIT") {
      show_ondemand(e.data.id, field);
    }
    console.log(e);
    clickable?.find((i) => i.field === field)?.onClick(e.data, e);
    if (e.colDef.column_type === "multifile") {
      setMultiFileData({
        id: e.data.id,
        field: field,
        model: model,
        label: e.colDef.headerName,
      });
    }
  };

  const [pendingChange, setPendingChange] = useState(null);

  const onCellValueChanged = (e) => {
    if (interceptChange) {
      interceptChange(e);
      return;
    }
    let field = e.colDef.field;
    let newValue = e.newValue;
    let id = e.data.id;

    if (newValue === true) {
      newValue = 1;
    } else if (newValue === false) {
      newValue = 0;
    }

    let data_array = [
      { key: "id", value: id },
      { key: "field", value: field },
      { key: "value", value: newValue },
    ];

    if (e.colDef.save_history !== "false" && e.colDef.save_history) {
      setPendingChange({ data: data_array, e: e });
      return;
    }

    postUpdate(data_array).then((newData) => {
      if (!newData) return;

      let row = gridApi?.getRowNode(e.node.id);
      if (!row) return;

      row.setData(newData);
    });
  };

  const postUpdate = (data_array, date) => {
    let url = `${model}/update`;
    if (date) {
      url += `/${date}`;
    }
    return apiPost(
      url,
      data_array,
      (response) => {
        console.log("Success: ", response.data);
        updateDataInArray(model, response.data);
        if (onUpdateResponse) {
          onUpdateResponse(response);
        }
        return response.data;
      },
      (error, response) => {
        alert("ERROR: " + error);
        console.error("Response with ERROR: ", response);
      }
    );
  };

  //   const onGridReady = (params) => {
  //     setGridApi(params.api);
  //     setColumnApi(params.columnApi);
  //   };

  const handleAdd = (item) => {
    let newData = rowData ? rowData.concat(item) : [item];
    setRowData(newData);
    if (model === "issued_invoices") {
      clickable[0]?.onClick();
    }
    addToDataArray(model, item);
  };

  const resizeAll = () => {
    if (columnApi) {
      const allColumnIds = [];
      columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      columnApi.autoSizeColumns(allColumnIds, false);
    }
  };

  useEffect(() => {
    if (!rowData) {
      setFilteredData(null);
      return;
    }
    let tempData = filter
      .byAccessAndFilter(rowData, model)
      .filter(rowDataFilter);
    if (handleRowData) {
      tempData = handleRowData(tempData);
    }
    setFilteredData(tempData);
  }, [handleRowData, rowData, filter, model, rowDataFilter]);

  let body = addPropsToChildren(children, {
    onAdd: handleAdd,
    selected: selectedRows,
    setFilter: setRowDataFilter,
    rowData: rowData,
  });

  if (frameworkComponents) {
    frameworkComponents = {
      ...frameworkComponents,
      defaultFrameworkComponents,
    };
  } else {
    frameworkComponents = defaultFrameworkComponents;
  }

  const [selectedTemplate, setSelectedTemplate] = useState(
    localStorage.getItem(`${model}_column_template`) ?? "default"
  );

  useEffect(() => {
    localStorage.setItem(`${model}_column_template`, selectedTemplate);
  }, [selectedTemplate]);

  const [columnState, setColumnState] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
  };

  const onDragStopped = (params) => {
    setColumnState(params.columnApi.getColumnState());
  };

  const onColumnVisible = (params) => {
    setColumnState(params.columnApi.getColumnState());
  };
  const [template, setTemplate] = useState({});
  const [open, setOpen] = useState(false);
  const [templateName, setTemplateName] = useState([]);
  const [templateLable, setTemplateLable] = useState("");

  const lable = templateLable ? templateLable : "all";

  useEffect(() => {
    if (!needTemplate) return;
    HttpService.get(UrlService.apiDomain() + `api/get-templates`)
      .then((response) => {
        const loaded = response.data ?? [];
        loaded.push({
          label: "Nový",
          value: "default",
          data: null,
        });
        setTemplateName(loaded);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    HttpService.get(
      UrlService.apiDomain() +
        `api/templates/${model}/table/${selectedTemplate}/${lable}`
    )
      .then((response) => {
        setTemplate(response.data);
        setColumnState(JSON.parse(response.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [selectedTemplate]);

  useEffect(() => {
    if (columnState !== null && columnApi) {
      columnApi.setColumnState(columnState);
    }
  }, [columnState, columnApi]);

  function addRemoveButtonToEndAdornment(endAdornment) {
    const children = [
      ...Children.toArray(endAdornment.props.children),
      <IconButton
        sx={{ p: 0 }}
        onClick={() => {
          handleClickOpen();
        }}
      >
        <Delete />
      </IconButton>,
    ];

    return cloneElement(endAdornment, {}, children);
  }

  function addSaveButtonToEndAdornment(endAdornment) {
    const children = [
      ...Children.toArray(endAdornment.props.children),
      <IconButton
        sx={{ p: 0 }}
        onClick={() => {
          saveTemplate({
            value: selectedTemplate,
            label: templateLable,
            data: columnState,
          });
        }}
      >
        <Save />
      </IconButton>,
    ];

    return cloneElement(endAdornment, {}, children);
  }

  const saveTemplate = async (data) => {
    await HttpService.post(
      UrlService.apiDomain() + `api/templates/${model}/table`,
      data
    ).catch((error) => {
      console.log(error);
    });
  };

  const deleteTemplate = async () => {
    try {
      const result = await HttpService.delete(
        UrlService.apiDomain() + `api/templates/${selectedTemplate}`
      );
      setTemplateName(
        templateName.filter((template) => template.value !== selectedTemplate)
      );
      setSelectedTemplate("default");
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let table = (
    <div
      id="myGrid"
      className="ag-theme-material"
      style={
        small
          ? { width: "100%", height: "35vh", minHeight: "100%" }
          : {
              width: "100%",
              height: "50vh",
              minHeight: "100%",
            }
      }
    >
      <ThemeProvider theme={theme}>
        <AgGridReact
          style={{ width: 500, height: 200 }}
          modules={AllCommunityModules}
          rowData={filteredData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          onDragStopped={onDragStopped}
          onCellValueChanged={onCellValueChanged}
          onCellClicked={onCellClicked}
          onSelectionChanged={() => setSelectedRows(gridApi.getSelectedRows())}
          onSortChanged={(e) => {
            e.api.refreshCells();
          }}
          onGridColumnsChanged={() => {
            resizeAll();
          }}
          onRowDataChanged={() => {
            resizeAll();
          }}
          getRowStyle={getRowStyle}
          enableCellChangeFlash={true}
          rowSelection={"multiple"}
          suppressContextMenu={true}
          frameworkComponents={frameworkComponents}
          localeText={locText}
          popupParent={document.querySelector("body")}
          {...gridProps}
        />
      </ThemeProvider>
    </div>
  );

  if (small) {
    return table;
  }

  return (
    <>
      <MultiFileModal
        data={multiFileData}
        onClose={() => setMultiFileData(null)}
        isOpen={multiFileData !== null}
      />
      <DateModal
        isOpen={!!pendingChange}
        onClose={() => setPendingChange(null)}
        onSubmit={(date) => {
          postUpdate(pendingChange.data, date).then((newData) => {
            if (!newData) return;

            let row = gridApi?.getRowNode(pendingChange.e.node.id);
            if (!row) return;

            row.setData(newData);
          });
          setPendingChange(null);
        }}
      />
      <Notes
        open={noteOpen}
        id={noteId}
        model={model}
        onClose={() => {
          setNoteOpen(false);
        }}
      />
      <Helmet>
        <title>{helmet}</title>
      </Helmet>
      <Box
        sx={
          style ?? {
            backgroundColor: "background.default",
            height: "100%",
            py: 4,
          }
        }
      >
        <Container
          maxWidth={settings.compact ? "xl" : false}
          style={{ width: "100%", height: "100%" }}
        >
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={3}>
              <Typography color="textPrimary" variant="h5">
                {t(title)}
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ mr: 2, display: "flex" }}>
                {needTemplate && (
                  <Autocomplete
                    size={"small"}
                    sx={{ width: "200px", mx: 1 }}
                    value={
                      templateName.find((t) => t.value === selectedTemplate) ??
                      ""
                    }
                    disableClearable
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Šablóna"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment:
                            selectedTemplate !== "default"
                              ? addRemoveButtonToEndAdornment(
                                  addSaveButtonToEndAdornment(
                                    params.InputProps.endAdornment
                                  )
                                )
                              : params.InputProps.endAdornment,
                        }}
                      />
                    )}
                    options={templateName ?? []}
                    onChange={(e, newVal) => {
                      if (typeof newVal === "string") {
                        let value = newVal
                          .toLowerCase()
                          .trim()
                          .replace(" ", "_");
                        console.log("Value", value);
                        if (
                          newVal &&
                          !templateName.find(
                            (template) => template.value === value
                          )
                        ) {
                          setTemplate({
                            value: value,
                            label: newVal,
                            data: columnState,
                          });
                          setTemplateName([
                            ...templateName,
                            {
                              value: value,
                              label: newVal,
                            },
                          ]);
                          saveTemplate({
                            value: value,
                            label: newVal,
                            data: columnState,
                          });
                        }
                        setSelectedTemplate(value);
                      } else {
                        setSelectedTemplate(newVal.value);
                        setTemplateLable(newVal.label);
                      }
                    }}
                  />
                )}
                {ready && body}
                {ready && (
                  <>
                    <Button
                      sx={{ ml: 4, px: 2 }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        gridApi.exportDataAsExcel({
                          processCellCallback: (params) => {
                            console.log(params.value, params);

                            if (
                              params.value &&
                              typeof params.value == "string" &&
                              /^\-?[0-9]+\,[0-9]{3}\.[0-9]{2}$/.test(
                                params.value
                              ) == true
                            ) {
                              params.value = params.value.substring(
                                0,
                                params.value.length - 3
                              );

                              params.value = params.value.replace(",", "");
                            } else if (
                              params.value &&
                              typeof params.value == "string" &&
                              params.value == "-"
                            ) {
                              params.value = "0";
                            }

                            const colDef = params.column.getColDef();

                            if (colDef.valueFormatter) {
                              const valueFormatterParams = {
                                ...params,
                                data: params.node.data,
                                node: params.node,
                                colDef: params.column.getColDef(),
                              };

                              return colDef.valueFormatter(
                                valueFormatterParams
                              );
                            }

                            return params.value;
                          },
                        });
                      }}
                    >
                      Excel
                    </Button>
                    <Button
                      sx={{ ml: 2, px: 2 }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        gridApi.setFilterModel(null);
                        gridApi.onFilterChanged();
                        filter.clear();
                      }}
                    >
                      Bez filtrů
                    </Button>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{ mt: 3 }}
            style={{ width: "100%", height: "calc(100% - 50px)" }}
          >
            {table}
          </Box>
        </Container>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t("Potvrdit odstranění")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t(
                "Jste si jisti, že chcete odstranit tuto šablóna a všechny šablony s ní spojené?"
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {t("Ne")}
            </Button>
            <Button onClick={deleteTemplate} color="primary" autoFocus>
              {t("Ano")}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}
