import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import useFilter from "../hooks/useFilter";
import $ from "jquery";
import {Autocomplete, TextField} from "@material-ui/core";
import {getName} from "../services/getColumnDefinitions";

export default forwardRef((props, ref) => {
    const {t} = useTranslation();
    const filter = useFilter();
    const [value, setValue] = useState([]);
    const [optionsArray, setOptionsArray] = useState([]);
    const inputRef = useRef(null);

    function parse(data) {
        return data ? data.toString().split('//') : [];
    }

    function stringify(data) {
        return data.map(d => d.value).join('//');
    }


    async function getOptions() {
        if (props.column.colId === 'allowed_buildings'
            || props.column.colId === 'allowed_companies'
            || props.column.colId === 'allowed_projects'
            || props.column.colId === 'allowed_categories') {
            let options = props.data_array.map(data => {
                return {
                    value: data.id,
                    label: data.name
                }
            })
            options.push({value: 0, label: getName(0, null)});
            setOptionsArray(options);
        } else {
            let options = filter.byAccessAndFilter(props.data_array, props.data_array_model).map(data => {
                return {
                    value: data.id,
                    label: getName(data, props.data_array_model)
                }
            });
            setOptionsArray(options);
        }
    }

    useEffect(() => {
        getOptions();
    }, [filter]);

    function getVal() {
        let selected = parse(props.value);
        selected = selected.map(val => {
            let item = optionsArray.find(data => data.value.toString() === val.toString());
            return {
                value: val,
                label: item ? item.label : 'not found'
            }
        });
        console.log("Selected", selected);
        setValue(selected);
    }

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return stringify(value);
            },
            afterGuiAttached: async () => {
                await getOptions();
                getVal();


                inputRef.current.focus();
                inputRef.current.click();
                $('#mycstselect svg').click();
            },

            isPopup: () => {
                return true;
            }
        };
    });

    console.log("Value", value);
    console.log("Options", optionsArray);

    return (
        <Autocomplete
            multiple
            ref={inputRef}
            options={optionsArray}
            fullWidth
            sx={{minWidth: '300px', maxWidth: '300px'}}
            value={value ?? []}
            onChange={(_, data) => {
                if (data.length === 0) {
                    let confirmed = window.confirm(t('Clear value?'));
                    if (!confirmed) return;
                }
                setValue(data);
            }}
            renderInput={(params) =>
                <TextField {...params}
                           sx={{
                               '& .MuiInputBase-input': {
                                   color: 'black'
                               }
                           }}
                           label={t(props.colDef.headerName)}/>}/>

    )
})