import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {AppBar, Box, Button, IconButton, Toolbar} from '@material-ui/core';
import {experimentalStyled} from '@material-ui/core/styles';
import MenuIcon from '../../icons/Menu';

import AccountPopover from './AccountPopover';
import Logo from '../Logo';
import RefreshIcon from '@material-ui/icons/Refresh';
import FilterSelect from "../filter/filterSelect";
import AddRentWithTenant from "../../pages/rent/addRentWithTenant/addRentWithTenant";

const DashboardNavbarRoot = experimentalStyled(AppBar)(({theme}) => ({
    ...(theme.palette.mode === 'light' && {
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
        color: theme.palette.primary.contrastText
    }),
    ...(theme.palette.mode === 'dark' && {
        backgroundColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.divider}`,
        boxShadow: 'none'
    }),
    zIndex: theme.zIndex.drawer + 100
}));

const DashboardNavbar = (props) => {
    const {onSidebarMobileOpen, ...other} = props;
    let logo_link = '/';
    if (localStorage.getItem('my_user') != null) logo_link = '/dashboard';
    return (
        <DashboardNavbarRoot sx={{
            backgroundColor: 'background.paper',
            color: 'text.secondary'
        }} {...other}>
            <Toolbar sx={{minHeight: 64}}>
                <IconButton
                    color="inherit"
                    onClick={onSidebarMobileOpen}
                    sx={{
                        display: {
                            lg: 'none'
                        }
                    }}
                >
                    <MenuIcon fontSize="small"/>
                </IconButton>
                <RouterLink to={logo_link}>
                    <Logo
                        sx={{
                            display: {
                                lg: 'inline',
                                xs: 'none'
                            },
                            height: 40,

                        }}
                    />
                </RouterLink>
                <FilterSelect/>
                <AddRentWithTenant/>
                <Box
                    sx={{
                        flexGrow: 1,
                        ml: 2
                    }}
                />
                {/*<LanguagePopover/>*/}
                <Button onClick={() => {
                    localStorage.setItem('public_settings_version', '0');
                    localStorage.setItem('private_settings_version', '0');
                    sessionStorage.clear();
                    window.location.reload();
                }}><RefreshIcon/></Button>
                <Box sx={{ml: 2}}>
                    <AccountPopover/>
                </Box>
            </Toolbar>
        </DashboardNavbarRoot>
    );
};

DashboardNavbar.propTypes = {
    onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
