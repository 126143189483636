import {Button, Grid} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ModalWrapper from "./ModalWrapper";
import addPropsToChildren from "../../utils/addPropsToChildren";

export default function ModalWithButton({title, children, size, buttonProps, onOpen, onClose}) {
    const {t} = useTranslation();
    const [isModalOpened, setIsModalOpened] = useState(false)

    const close = () => {
        setIsModalOpened(false)
    }

    useEffect(() => {
        if (isModalOpened && onOpen) {
            onOpen();
        } else if (onClose) {
            onClose();
        }
    }, [isModalOpened])

    let body = addPropsToChildren(children, {onClose: close});

    return (
        <>
            <Button
                color="primary"
                type="button"
                variant="contained"
                sx={{height: "100%", px: 2}}
                onClick={() => setIsModalOpened(true)}
                {...buttonProps}
            >
                {t(title)}
            </Button>
            <ModalWrapper onClose={close} isOpen={isModalOpened} size={size}>
                {body}
            </ModalWrapper>
        </>
    )
}