const sidebarColumns = [
    {
        'model': 'projects',
        'title': 'Kolonky projekty',
    },
    {
        'model': 'companies',
        'title': 'Kolonky firem',
    },
    {
        'model': 'buildings',
        'title': 'Kolonky budov',
    },
    {
        'model': 'facilities',
        'title': 'Kolonky objektů',
    },
    {
        'model': 'clients',
        'title': 'Kolonky nájemců',
    },
    {
        'model': 'rents',
        'title': 'Kolonky nájmů',
    },
    {
        'model': 'suppliers',
        'title': 'Kolonky dodavatelů',
    },
	{
        'model': 'supplier_account_numbers',
        'title': 'Kolonky čísla dodavatelských účtů',
    },
    {
        'model': 'issued_invoices',
        'title': 'Kolonky V. Faktur',
    },
    {
        'model': 'received_invoices',
        'title': 'Kolonky P. Faktur',
    },
    {
        'model': 'measurement_units',
        'title': 'Kolonky měrných jednotek',
    },
    // {
    //     'model': 'meter_readings',
    //     'title': 'Kolonky stavů měřičů',
    // },
    // {
    //     'model': 'meter_types',
    //     'title': 'Kolonky typů měříčů',
    // },
    {
        'model': 'document_templates',
        'title': 'Šablóny dokumentů',
    },
    {
        'model': 'employees',
        'title': 'Uživatele',
    },
    {
        'model': 'cohabitants',
        'title': 'Spolubydlicí'
    },
    {
        'model': 'account_numbers',
        'title': 'Naše účty'
    },
    {
        'model': 'wire_transfers',
        'title': 'Bankovní převody'
    },
    {
        'model': 'invoice_items',
        'title': 'Položky faktur'
    },
    {
        'model': 'invoice_item_names',
        'title': 'Názvy položek faktur'
    },
    {
        'model': 'payments',
        'title': 'Příchozí platby'
    },
    {
        'model': 'currencies',
        'title': 'Měny'
    },
    {
        'model': 'cars',
        'title': 'Vozidla'
    },
    {
        'model': 'regular_payments',
        'title': 'Pravidelné platby'
    },
    {
        'model': 'debts',
        'title': 'Dluhy'
    },
    {
        'model': 'additional_texts',
        'title': 'Additional texts'
    },
]
export default sidebarColumns;
