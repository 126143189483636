import React, {useEffect, useState} from "react";
import apiGet from "../../../../api/apiGet";
import GridWrapper from "../../../../components/grid/gridWrapper";
import DownloadAttachment from "../../../../aggrid/downloadAttachment";
import {Autocomplete, Button, Checkbox, FormControlLabel, LinearProgress, TextField} from "@material-ui/core";
import InputAutoComplete from "../../../../components/inputs/autoComplete/InputAutoComplete";
import apiPost from "../../../../api/apiPost";
import {openBlob, setDownloadAttribute} from "../../../../utils/blobUtils";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import MobileDatePicker from "@material-ui/lab/MobileDatePicker";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import csLocale from "date-fns/locale/cs";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat'
import {getLocalJson} from "../../../../utils/localStorageUtills";
import InputMultiAutocomplete from "../../../../components/inputs/autoComplete/InputMultiAutocomplete";

const localeMap = {
    ru: ruLocale,
    cs: csLocale,
};

export default function GenerateDocuments({model, id, item}) {
    const {t} = useTranslation();
    const [locale] = useState(t('cs'));


    const [documents, setDocuments] = useState(null);
    const [templateId, setTemplateId] = useState(null);
    const [dataArray] = useState(() => {
        let templates = JSON.parse(localStorage.getItem('document_templates')).filter(template => template.model === model)
        console.log("Item", item);

        if (item) {
            // if (model === 'companies') {
            //     templates = templates.filter(template => template.dph === item.platce_dph)
            // }
            // else if (item.company_id) {
            //     let company = JSON.parse(localStorage.getItem('companies')).filter(i => i.id === item.company_id)[0]
            //     console.log("Company found ", company)
            //     if (company?.platce_dph !== null) {
            //         templates = templates.filter(template => template.dph === company.platce_dph || !!template.invoice_type)
            //     }
            // }
            if (model === 'issued_invoices') {
                if (!!item.invoice_type) {
                    templates = templates.filter(template => template.invoice_type === item.invoice_type)
                }
            }
        }
        return templates;
    });

    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState('now');
    const [dates, setDates] = useState([]);
    const [word, setWord] = useState(0);
    const [attachmentsOptions, setAttachmentsOptions] = useState(null);
    const [attachments, setAttachments] = useState(null);
    const [generationDate, setGenerationDate] = useState(null);


    useEffect(() => {
        let mounted = true;
        apiGet(`${model}/${id}/documents`).then(response => {
            if (mounted) {
                if (response.data && response.data.length > 0) {
                    setDocuments(response.data);
                } else {
                    setDocuments([]);
                }
            }
        });
        apiGet(`${model}/${id}/history/dates`).then(response => {
            if (mounted) {
                setDates(Object.values(response.data).sort());
            }
        });

        let colDefs = getLocalJson("columns").filter(col => col.model === model);
        let fileDefs = colDefs.filter(col => col.column_type === 'file' || col.column_type === 'multifile');
        if (fileDefs?.length > 0) {
            setAttachmentsOptions(fileDefs.map(i => {
                return {label: i.name, value: i.id}
            }));
        }
        return function cleanup() {
            mounted = false
        };
    }, [model, id]);

    const handleSubmit = () => {
        if (templateId) {
            setLoading(true);

            dayjs.extend(customParseFormat);
            let stringGenerationDate = dayjs(generationDate?.toISOString() ?? new Date()).format('YYYY-MM-DD');
            let isWord = word === 0 ? 'false' : 'true';
            apiPost(`${model}/documents/generate/${date}/${isWord}/${stringGenerationDate}`, [
                    {
                        key: 'twoSided',
                        value: false
                    },
                    {
                        key: 'documents',
                        value: templateId
                    },
                    {
                        key: 'models',
                        value: id
                    },
                    {
                        key: 'attachments',
                        value: attachments
                    }
                ],
                (blob) => {
                    setLoading(false);
                    if (!word) {
                        openBlob(blob);
                    } else {
                        let filename = 'download.docx';
                        openBlob(blob, (link) => setDownloadAttribute(link, filename));
                    }
                },
                (error) => {
                    setLoading(false);
                    alert('Error generating document: ' + error)
                },
                true);
        }
    };

    if (!documents) {
        return <LinearProgress fullWidth sx={{my: 5}}/>;
    }

    return (
        <GridWrapper
            style={{mt: 2}}
            helmet={`Dashboard: Documents List | Belo estate`}
            title={'Dokumenty'}
            data={documents}
            frameworkComponents={{downloadAttachment: DownloadAttachment}}
            colDefs={[
                {
                    headerName: "Soubor",
                    editable: false,
                    field: "attachment",
                },
                {
                    headerName: "Akce",
                    editable: false,
                    field: "id",
                    cellRenderer: "downloadAttachment",
                },
            ]}>
            <>
                <FormControlLabel sx={{width: '150px'}}
                                  control={<Checkbox value={word} onChange={(e) => setWord(e.target.checked ? 1 : 0)}/>}
                                  label={'Generovat docx'}/>
                <InputMultiAutocomplete
                    label={'Přílohy'}
                    onChange={(_, val) => {
                        setAttachments(val);
                    }}
                    required={false}
                    disabled={false}
                    data_array={attachmentsOptions}
                    data_array_model={'columns'}
                    valueSelector={(i) => i.value}
                    keySelector={(i) => i.label}
                    inputSx={{width: '300px', mr: '10px'}}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
                    <MobileDatePicker
                        value={generationDate}
                        label={'Datum generace'}
                        fullWidth
                        mask="__.__.____"
                        openTo="year"
                        clearable
                        onChange={(val) => {
                            setGenerationDate(val);
                        }}
                        renderInput={(params) => {
                            if (generationDate == null) {
                                params.value = 'teď';
                                params.inputProps.value = 'teď';
                            }
                            return <TextField
                                sx={{width: '120px', mr: 2}}
                                variant={"outlined"}
                                {...params}
                            />
                        }}
                    />
                </LocalizationProvider>
                {dates.length > 1 && <Autocomplete
                    sx={{width: '200px', mr: 2}}
                    options={dates}
                    value={date}
                    onChange={(event, value) => {
                        setDate(value ?? 'now');
                    }}
                    renderInput={(params) =>
                        <TextField {...params} label={'Datum historie'}
                                   inputProps={{...params.inputProps, maxLength: 30}}/>}/>}
                <InputAutoComplete
                    onChange={(f, i) => setTemplateId(i)}
                    data_array={dataArray}
                    valueSelector={i => i.id}
                    keySelector={i => i.name}
                    value={templateId}
                    name={'template'}
                    label={'Vyberte šablónu'}
                    inputSx={{width: '400px'}}
                />
                <Button
                    variant={'contained'}
                    sx={{ml: 2, width: '100px'}}
                    disabled={!templateId || loading}
                    onClick={handleSubmit}>{loading ? <LinearProgress sx={{width: '100px'}}/> : "Generovat"}</Button>
            </>

        </GridWrapper>
    )
}